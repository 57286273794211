import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import {
    getCompanyDetailsFormFields,
    saveInputsToLocalStorage,
    getInputsFromLocalStorage,
    getShowResultsFromLocalStorage,
    saveShowResultsToLocalStorage,
    getSortByFromLocalStorage,
    sortMarketplaceSuppliers,
    getActivePageNumberFromLocalStorage,
    setDescription, setTitle
} from "../../utils";

import { useSearchData } from "./useSearchData";

import Row from "react-bootstrap/Row";

import Banner from "../../components/Banner/Banner";
import { Results } from "./Results";
import style from "./marketplace.module.css";

/*import bannerImg from "../../assets/images/banners/Marketplace.png";*/
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import Btn from "../../components/Button/Button";
import { FiltersContainer } from "./FiltersContainer";
import { SortBy } from "../../components/SortBy/SortBy";
import Paginate from "../../components/Paginate/Paginate";
import MailModal from "../../components/MailModal/EnquiryModal";

window.addEventListener("beforeunload", () => {
    localStorage.removeItem("search-inputs");
    localStorage.removeItem("show-results");
    localStorage.removeItem("sort-by");
    localStorage.removeItem("page-number");
});

export const Marketplace = (props) => {
    const radius = 999;
    const location = useLocation();
    const [submitInputs, setSubmitInputs] = useState({});
    const [inputs, setInputs] = useState({
        FreeText: "",
        location: [],
        accr: [],
        service: [],
        Postcode: "",
        Radius: radius
    });
    const [showResults, setShowResults] = useState(false);
    const [locations, setLocations] = useState([]);
    const [services, setServices] = useState([]);
    const [displayItems, setDisplayItems] = useState([]);
    const [accrs, setAccrs] = useState([]);
    const [savedSearchInputs, setSavedSearchInputs] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activePage, setActivePage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [display, setDisplay] = useState(null);

    const { searchResults, error, isLoading, fetchData } = useSearchData();

    const handleFilterPopulation = useCallback(
        (fields) => {
            if (fields.code !== "SUCCESS") {
                return;
            }

            fields.fields.forEach((field) => {
                switch (field.fieldName) {
                    case "Areas_Covered":
                        const locations = field.selectList.map(x => x.value);
                        setLocations(locations);
                        break;
                    case "Service_Type":
                        const services = field.selectList;
                        const filteredServices = services.filter((services) => services.value !== "-None-");
                        setServices(filteredServices);
                        break;
                    case "Product_Measure_Standards":
                        const accr = field.selectList.map(x => x.value);
                        const filteredAccr = accr.filter((accr) => accr !== "None of these");
                        setAccrs(filteredAccr);
                        break;
                    default:
                }
            });
        },
        [setLocations, setServices, setAccrs]
    );

    useEffect(() => {
        setTitle("Green Economy | Marketplace: Find green products & services");
        setDescription("Search for local installers, tradespeople and suppliers of net zero products and services on the Green Economy Marketplace.")
    }, []);

    useEffect(() => {
        const fetchFormFields = async () => {
            const fields = await getCompanyDetailsFormFields();
            handleFilterPopulation(fields);
        };

        fetchFormFields();
    }, [handleFilterPopulation]);

    //disabled eslint warnings as fixes cause infinite loops
    useEffect(() => {
        const inputs = getInputsFromLocalStorage();
        if (inputs) {
            fetchData(inputs);
            setSavedSearchInputs(inputs);
            setInputs(inputs)
        } else {
            fetchData(submitInputs);
        }
        const showResults = getShowResultsFromLocalStorage();
        setShowResults(showResults);
    }, [submitInputs]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (searchResults.length === 0) {
            setDisplay([]);
            setDisplayItems([]);
        } else {
            const sortBy = getSortByFromLocalStorage();
            if (sortBy) {
                const sortedSuppliers = sortMarketplaceSuppliers(searchResults, sortBy, displayItems);
                setDisplayItems(sortedSuppliers);
            } else {
                setDisplayItems(searchResults);
            }

            const savedPageNumber = getActivePageNumberFromLocalStorage();
            if (savedPageNumber) {
                setActivePage(savedPageNumber);
            }
        }
    }, [searchResults]);// eslint-disable-line react-hooks/exhaustive-deps

    let multipleEmail = [];

    if (displayItems.length) {
        displayItems.map((company) => multipleEmail.push(company.email));
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const handlePostcodeChange = (event) => {
        setInputs((values) => ({ ...values, ["Postcode"]: event }));
    };

    const handleRadiusChange = (event) => {
        event.target.value = event.target.value.replace(/\D/g, "");
        handleChange(event);
    };

    const handleCheck = (event, type) => {
        const name = event.target.name;

        let section = [];
        switch (type) {
            case "location":
                section = Object.values(inputs.location);
                break;
            case "accr":
                section = Object.values(inputs.accr);
                break;
            case "service":
                section = Object.values(inputs.service);
                break;
            default:
                break;
        }

        if (event.target.checked) {
            section = section.concat([name]);
        } else {
            section = section.filter(function (val) {
                return val !== name;
            });
        }
        setInputs((values) => ({ ...values, [type]: section }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        localStorage.removeItem("sort-by");

        if (inputs.Radius == "" || inputs.Radius == null) {
            inputs.Radius = radius;
        }

        if (
            inputs.FreeText !== "" ||
            inputs.location.length > 0 ||
            inputs.accr.length > 0 ||
            inputs.service.length > 0 ||
            inputs.Postcode !== "" 
        ) {
            setShowResults(true);
            saveShowResultsToLocalStorage(true);
        } else {
            setShowResults(false);
            saveShowResultsToLocalStorage(false);
        }

        setSubmitInputs(inputs);
        saveInputsToLocalStorage(inputs);
        setSavedSearchInputs(inputs);
        setActivePage(0);
    };

    const clearForm = (postcodeAutocompleteRef) => {
        setInputs({ FreeText: "", location: [], accr: [], service: [], Postcode: "", Radius: radius });
        setSubmitInputs({ FreeText: "", location: [], accr: [], service: [], Postcode: "", Radius: radius });
        saveInputsToLocalStorage({ FreeText: "", location: [], accr: [], service: [], Postcode: "", Radius: radius });
        setSavedSearchInputs({ FreeText: "", location: [], accr: [], service: [], Postcode: "", Radius: radius });
        setShowResults(false);
        saveShowResultsToLocalStorage(false);

        localStorage.removeItem("search-inputs");
        localStorage.removeItem("show-results");
        localStorage.removeItem("sort-by");
        localStorage.removeItem("page-number");

        postcodeAutocompleteRef?.current?.clear();
    };

    return (
        <>
            <Banner
                title="MARKETPLACE"
                src={bannerImg}
                alt="Landing page banner image"
                text="SEARCH FOR LOCAL SUPPLIERS OF"
                subText= "NET ZERO PRODUCTS AND SERVICES"
                color="white"
                bgcolour="#02be7c"
            />
            <MailModal show={show} handleClose={handleClose} multipleEmail={multipleEmail} title="Multiple Company Enquiry" template={1} />
            <div className={style.infoBar}>
                <p>
                    To list your products and services on the Marketplace join Green Economy today. &nbsp;&nbsp;
                    <Link to={`/membership`}>
                        <Btn className={style.bannerBtn} text="Take me there" color="LightGreen" />
                    </Link>
                </p>
                <p>
                    Our directory of installers, equipment suppliers and solution providers is here to help you make an
                    informed decision when sourcing local suppliers for your net zero project or business.
                </p>
            </div>
            <div className={style.mpContainer}>
                <FiltersContainer
                    locations={locations}
                    services={services}
                    accrs={accrs}
                    clearForm={clearForm}
                    handleCheck={handleCheck}
                    handleSubmit={handleSubmit}
                    savedSearchInputs={savedSearchInputs}
                    handleChange={handleChange}
                    handlePostcodeChange={handlePostcodeChange}
                    handleRadiusChange={handleRadiusChange}
                    inputs={inputs}
                />
                <div className={style.formResultsContainer}>
                    {!isLoading && displayItems?.length > 0 && (
                        <form id="Searchform">
                            <div className={style.mpContainerRight}>
                                <Row className="mb-2" style={{ display: "flex" }}>
                                    <div style={{}} className={style.buttonContainer}>
                                        <SortBy
                                            displayItems={displayItems}
                                            setDisplayItems={setDisplayItems}
                                            searchResults={searchResults}
                                            location={location}
                                        />

                                        <Btn
                                            type="button"
                                            color="LightGreen"
                                            onClick={handleShow}
                                            text="Enquire All"
                                            width="50%"
                                        />
                                    </div>
                                </Row>
                            </div>
                        </form>
                    )}
                    <div className={style.resultsContainer}>
                        {showResults ? (
                            <>
                                <Results
                                    error={error}
                                    isLoading={isLoading}
                                    location={location}
                                    displayItems={display}
                                    activePage={activePage}
                                />
                            </>
                        ) : (
                            <div className={style.noResultInfo}>
                                    <h4>Search for a supplier</h4>
                                    <p>To find a local supplier, please use the keyword search e.g. electric vehicle installation, LED light fitter, waste management and/or a combination of the Location, Services and Accreditations drop-down lists to return results that meet your specific requirements.</p>
                                    <h4>Find Out more</h4>
                                    <p>Once you have your results, hit the 'Find Out More' link to view the supplier's online profile and make an individual enquiry</p>
                                    <h4>Multiple Enquiries</h4>
                                    <p>To contact all results hit the 'Enquire All' button to send an enquiry to all suppliers listed for your search</p>
                                    <h4>Don't see what you need?</h4>
                                    <p>The Green Economy team can provide tailored support for your procurement requirements and help you to find the right solution for your business. &nbsp;
                                        <a className="PLinkTo"
                                            href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero"}
                                        >
                                            Find out more
                                        </a>
                                        {/*<Link to={`/buyers`} className="PLinkTo">*/}
                                        {/*    Find out more about bespoke support for buyers.*/}
                                        {/*</Link>*/}
                                    </p>
                                    <p>&nbsp;</p>                            </div>
                        )}
                        {displayItems.length !== 0 && (
                            <Paginate
                                activePage={activePage}
                                setActivePage={setActivePage}
                                items={displayItems}
                                pageSize={5}
                                setDisplay={setDisplay}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                isLoading={isLoading}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
