import React from "react";
import { Link } from "react-router-dom";
import Btn from "../Button/Button";

import styles from "./Banner.module.css";

const Banner = (props) => {
  return (
      <div className={styles.bannerContainer} style={{ color: `${props.color}`, backgroundColor:`${props.bgcolour}` }}>
          <img className={styles.bannerImg} src={props.src} alt={props.alt} />
          <div className={styles.bannerContentContainer}>
              <div className={styles.pLine }></div>
              <h1 className={styles.bannerTitle}>{props.title}</h1>
              <p className={styles.bannerText}>
                  {props.text}
              </p>
              {props.subText &&
                  <p className={styles.bannerText}>{props.subText}</p>
              }
              {props.btnText &&
                  <span>
                      <Link to={`/${props.link}`}>
                          <Btn className={styles.bannerBtn} text={props.btnText} color="White" />
                      </Link>
                  </span>}
          </div>
      </div>
  );
};
export default Banner;

