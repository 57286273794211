import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { setDescription, setTitle } from "../../utils";
import bannerImg from "../../assets/images/banners/SalesHub.png";
import buyers from "../../assets/images/pageIcons/ge-buyers.png";
import suppliers from "../../assets/images/pageIcons/ge-suppliers.png";
import marketplace from "../../assets/images/pageIcons/ge-marketplace.png"
import { PageCard } from "../../components/PageCard/PageCard";
import styles from "./Opportunities.module.css"
import Banner from "../../components/Banner/Banner";
import { getOpportunities } from "../../utils";
import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";

export const Opportunities = () => {

    const [inputs, setInputs] = useState({
        opportunities: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setTitle("Green Economy | Sales Hub: Low carbon tender & green sales");
        setDescription("Green Economy�s Sales Hub provides tailored sales opportunities for green sector installers and suppliers.")
    }, []);

    const fetchOpportunities = async () => {
        try {
            setIsLoading(true);
            const data = await getOpportunities();
            setInputs({ "opportunities": data });
        } catch (error) {
            setError({ message: "Uh oh, looks like something's went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchOpportunities();
    }, []);

    return (
        <>
            <Banner
                title="Sales"
                src={bannerImg}
                alt="Sales"
                color='white'
            />

            <div className={styles.opportunitiesContainer}>
                <h2 className={styles.opportunitiesTitle}>Sales</h2>

                <div className={styles.opportunitiesIntroduction}>
                    <p>Green Economy is committed to working with buyers as well as suppliers to make procurement processes more accessible to businesses providing green technology and services.
                        The opportunities below are just a flavour of the projects, tenders and frameworks seeking green suppliers and installers.
                        Green Economy members access new opportunities first - sign up today to ensure you don't miss out.
                    </p>
                    <p className="text-center"><Link to="/memebership" className="Purple btn btn-primary">
                        Access sales opportunities
                    </Link></p>
                </div>

                {error &&
                    <Error error={error} id="opportunity-error" page="opportunities-page" />
                }

                {isLoading &&
                    <Loader page="opportunities" />
                }

                {!isLoading && inputs && inputs.opportunities &&
                    <div className={styles.opportunitiesCards}>
                        <ListGroup variant="flush">
                            {inputs.opportunities.map((opportunity) => (
                                <div className={styles.opportunitiesCard} key={opportunity.id}>
                                    <ListGroup.Item>
                                        <p className={styles.opportunitiesTitle}>{opportunity.deal_Name}</p>
                                        {opportunity.closing_Date &&
                                            <p>Closing Date: {opportunity.closing_Date.substring(0, 10)}</p>
                                        }
                                        <p>Location: {opportunity.location}</p>
                                        <p className={styles.opportunitiesCardDetail}>{opportunity.description}</p>
                                    </ListGroup.Item>
                                </div>
                            ))}
                        </ListGroup>
                    </div>
                }

                <h2 className={styles.opportunitiesMore}>Access sales opportunities</h2>

                <div className={styles.opportunitiesCardContainer}>
                    <div className={styles.salesCard}>
                        <PageCard
                            width="100%"
                            header="Supplier?"
                            text="Join Green Economy to raise your visibility with buyers and receive bespoke Sales Hub opportunities."
                            page="suppliers"
                            hasLogo={true}
                            logo={suppliers}
                            link="prices"
                            styling="standard"
                        />
                    </div>
                    <div className={styles.salesCard}>
                        <PageCard
                            width="100%"
                            header="Buyer?"
                            text="Let us know about your new opportunities and let us find your perfect project partner"
                            page="buyers"
                            hasLogo={true}
                            logo={buyers}
                            link="buyers"
                            styling="standard"
                        />
                    </div>
                </div>
                <PageCard
                    width="100%"
                    header="Marketplace"
                    text="Use our directory of installers, equipment suppliers and solution providers to make an informed decision on your sustainability projects and support your local green economy"
                    page="marketplace"
                    hasLogo={true}
                    logo={marketplace}
                    link="marketplace"
                    styling="marketplace"
                />
            </div>
        </>
    );
};
