/*import "./RadioGroup.module.css";*/

const RadioButton = (props) => {
    return (
        <button
            type="button"
            role="radio"
            aria-checked={"false"}
            tabIndex="-1"
            className="radio-group__button"
            onClick={props.onClick}
            data-label={props.label}
            data-value={props.value}
        >
            {props.label}
        </button>
    );
};

export default RadioButton;
