import React, { useState } from "react";
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

const AsyncTypeahead = withAsync(Typeahead);
const SEARCH_URI = 'https://api.postcodes.io/postcodes/';
const PostcodeAutocomplete = React.forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([""]);

    const handlePostcodeSearch = (query) => {
        setIsLoading(true);

        fetch(`${SEARCH_URI}${query}/autocomplete?limit=26`)
            .then((resp) => resp.json())
            .then(({ result }) => {
                if (result == null) {
                    setOptions([]);
                }
                else {
                    setOptions(result);
                    handleInputChange(query);
                }
                setIsLoading(false);
            });
    };

    const handleChange = (event) => {
        handleInputChange(event[0]);
    };

    const handleInputChange = (event) => {
        if (props.handlePostcodeChange) {
            props.handlePostcodeChange(event);
        }
    };

    return (
        <AsyncTypeahead
            ref={ref}
            filterBy={() => true}
            id="postcodeTypeahead"
            isLoading={isLoading}
            minLength={2}
            onSearch={handlePostcodeSearch}
            options={options}
            placeholder="Search By Postcode"
            emptyLabel="No postcode found"
            onChange={(event) => handleChange(event)}
            renderMenuItemChildren={(option) => (
                <>
                    <span>{option}</span>
                </>
            )}
            inputProps={{
                style: {
                    'boxShadow': 'none'
                }
            }}
        />
    );
});

export default PostcodeAutocomplete;
