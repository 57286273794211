import bannerImg from "../../assets/images/banners/g-banner-sml.jpg";
import { Header } from "../../components/Header/Header";
import styles from "./Cookies.module.css"
import { useScript } from "../../utils/useScript";

export const Cookies = () => {
    const { Cookie } = useScript('https://consent.cookiebot.com/73d37fea-f256-4f14-85aa-7acf1b13e6d8/cd.js', 'CookieDeclaration', 'CookieDeclaration')
    return (
        <>
            <Header text="Cookie Policy" bgColour="#01402E" img={bannerImg} />
            <div className={styles.cookiesContainer}>
                <h2 className={styles.cookiesTitle}>Cookie Policy</h2>
                <div id="CookieDeclaration"></div>
                    {Cookie}
                {/*<script id="CookieDeclaration" src=https://consent.cookiebot.com/73d37fea-f256-4f14-85aa-7acf1b13e6d8/cd.js type="text/javascript" async></script>*/}
            </div>

        </>
    );
};