import { useState } from "react";

import styles from "./Profile.module.css";
import { FaPlus } from "react-icons/fa";

import { SupplierHeader } from "../../components/SupplierHeader/SupplierHeader";
import { CompanyDetails } from "./formSections/CompanyDetails";
import { YourOrganisation } from "./formSections/YourOrganisation";
import { CompanyLogo } from "./formSections/CompanyLogo";
import { useCompanyDetailsFormFields } from "./useCompanyDetailsFormFields";
import { Loader } from "./../../components/Loader/Loader";

export const Profile = ({ supplier }) => {
    const [activeAccordion, setActviveAccordion] = useState("company-details");

    const { formFields, isLoading, error } = useCompanyDetailsFormFields();
    const fields = formFields.fields;
    const moveToNextSection = (sectionName) => {
        setActviveAccordion(sectionName);
    };

    if (isLoading) {
        return <Loader page="profile" />;
    }

    if (error) {
        return <h4 data-testid="profile-error">{error.message}</h4>;
    }

    return (
        <div className={styles.mainContent}>
            <SupplierHeader supplier={supplier} />
            {fields && fields.length > 0 && (
                <>
                    <CompanyDetails
                        supplier={supplier}
                        onClick={moveToNextSection}
                        accType={fields[0].selectList.map(x => x.value)}
                        busType={fields[2].selectList.map(x => x.value)}
                        tradeAssocMembership={fields[3].selectList.map(x => x.value)}
                        activeAccordion={activeAccordion}
                        nextAccordionSection="your-organisation"
                    />
                    <YourOrganisation
                        supplier={supplier}
                        onClick={moveToNextSection}
                        tradeAssocMembership={fields[2].selectList.map(x => x.value)}
                        areasCovered={fields[3].selectList.map(x => x.value)}
                        tradingAddressLine2={fields[5].selectList.map(x => x.value)}
                        serviceTypes={fields[6].selectList.map(x => x.value)}
                        activeAccordion={activeAccordion}
                        nextAccordionSection="your-logo"
                    />
                    <CompanyLogo supplier={supplier} onClick={moveToNextSection} />
                </>
            )}
            <section>
                <div className={styles.title}>
                    <p>Your interests</p>
                    <span>
                        <FaPlus />
                    </span>
                </div>
                <div style={{ height: "0px", overflow: "hidden" }}>
                    <div>Content here</div>
                </div>
            </section>
            <section>
                <div className={styles.title}>
                    <p>Products & Services</p>
                    <span>
                        <FaPlus />
                    </span>
                </div>
                <div style={{ height: "0px", overflow: "hidden" }}>
                    <div>Content here</div>
                </div>
            </section>
        </div>
    );
};
