import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import PostcodeAutocomplete from "../../components/PostcodeAutocomplete/PostcodeAutocomplete";
import Tree from "../../components/Tree/ServiceTree"
import Btn from "../../components/Button/Button";
import style from "./marketplace.module.css";
import "./mp.css";

export const FiltersContainer = ({
    locations,
    services,
    accrs,
    handleCheck,
    clearForm,
    handleSubmit,
    savedSearchInputs,
    handleChange,
    handlePostcodeChange,
    handleRadiusChange
}) => {
    let postcodeAutocompleteRef = React.useRef();
    return (
        <div className={style.mpContainerLeft}>
            <div className={style.clearSearchConatiner}>
                <h3 className={style.filterTitleContainerTitle}>Search</h3>
            </div>
            <form onSubmit={handleSubmit} id="SearchformOne">
                <div className={style.filterTitleContainer}>
                   
                    <div className={style.searchSubmitContainer}>
                    <input
                        type="text"
                        className={style.keywordInput}
                        id="FreeText"
                        data-testid="searchInput"
                        name="FreeText"
                        onChange={handleChange}
                        defaultValue={savedSearchInputs.FreeText || ""}
                        placeholder={"Search By Keyword"}
                    />
                        <Btn color="Green" type="submit" text="Search" />
                    </div>
                </div>
                <div className={style.clearSearchConatiner} style={{ marginTop: '8px' }} >
                    <p className={style.accordionHeader}>Postcode</p>
                    {/*<h3 className={style.filterTitleContainerTitle}>Postcode</h3>*/}
                </div>
                <div className={style.filterTitleContainer}>
                    <div className={style.searchSubmitContainer}>
                        <div className={ style.Col5} >
                            <PostcodeAutocomplete handlePostcodeChange={handlePostcodeChange} ref={postcodeAutocompleteRef} />
                        </div>
                        {/*<div className={style.Col3}>*/}
                        {/*    <label className={style.searchRadiusLabel}>Within(m)</label>*/}
                        {/*</div>*/}
                        {/*<div className={style.Col2}>*/}
                            
                        {/*    <input*/}
                        {/*        type="text"*/}
                        {/*        className={style.radiusInput}*/}
                        {/*        id="Radius"*/}
                        {/*        data-testid="radiusInput"*/}
                        {/*        name="Radius"*/}
                        {/*        onChange={handleRadiusChange}*/}
                        {/*        defaultValue={savedSearchInputs.Radius || "10"}*/}
                        {/*        maxLength="3"*/}
                        {/*    />*/}
                            
                        {/*</div>*/}
                    </div>
                </div>
                <Accordion flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <p className={style.accordionHeader}>Areas Covered</p>
                        </Accordion.Header>
                        <Accordion.Body className={style.accordionBody}>
                            {locations &&
                                locations.map((loc) => (
                                    <Form.Check
                                        key={loc}
                                        type="checkbox"
                                        label={loc}
                                        id={loc}
                                        name={loc}
                                        defaultChecked={savedSearchInputs.location?.includes(loc) ? true : false}
                                        onClick={(event) => handleCheck(event, "location")}
                                    />
                                ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <p className={style.accordionHeader}>Services</p>
                        </Accordion.Header>
                        <Accordion.Body className={style.accordionBody}>
                            {services &&
                                <Tree data={services} handleCheck={handleCheck} savedSearchInputs={savedSearchInputs} />
                             }
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <p className={style.accordionHeader}>Accreditations</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            {accrs.map((accr) => (
                                <Form.Check
                                    key={accr}
                                    type="checkbox"
                                    label={accr}
                                    id={accr}
                                    name={accr}
                                    defaultChecked={savedSearchInputs.accr?.includes(accr) ? true : ""}
                                    onClick={(event) => handleCheck(event, "accr")}
                                />
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    
                </Accordion>
                <div className={style.clearSearchConatiner}>
                    {/*<Btn color="Purple"  text="Search" width="49%" type="submit"  />*/}
                    {/*<Btn color="Purple" type="reset" text="Clear" width="49%" onClick={() => clearForm(postcodeAutocompleteRef)} />*/}
                    <Btn color="Green"  text="Search" width="49%" type="submit"  />
                    <Btn color="Green" type="reset" text="Clear" width="49%" onClick={() => clearForm(postcodeAutocompleteRef)} />
                </div>
            </form>
        </div>
    );
};
