import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import Home from "./pages/home/Home";
import { Marketplace } from "./pages/marketplace/Marketplace";
import { Events } from "./pages/events/Events";
import { News } from "./pages/news/News";
import { Sales } from "./pages/sales/Sales";
import { Opportunities } from "./pages/opportunities/Opportunities";
import Supplier from "./pages/supplier/Supplier";
import AboutUs from "./pages/aboutUs/aboutUs";
import Public from "./pages/public/public";
import Research from "./pages/rnd/research";
import Membership from "./pages/membership/membership";
import { Privacy } from "./pages/privacy/Privacy";
import { Terms } from "./pages/terms/Terms";
import { Accessibility } from "./pages/accessibility/Accessibility";
import { Cookies} from "./pages/cookies/Cookies";
import { useAuth } from "react-oidc-context";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "./components/Oidc/Authentication";

import "./assets/css/custom.css";
import "./assets/css/gcicons.css";
import { Buyers } from "./pages/buyers/Buyers";
import Accreditations from "./pages/accreditations/accreditations";
import { Dashboard } from "./pages/dashboard/Dashboard";
import Prices from "./pages/prices/Prices";
import LoginRedirect from "./pages/loginRedirect/LoginRedirect";
import RegisterUser from "./pages/registerUser/RegisterUser";
import { IndividualSuppliers } from "./pages/individualSuppliers/IndividualSuppliers";
import { PageNotFound } from "./pages/pageNotFound/PageNotFound";
import { Loader } from "./components/Loader/Loader";

import ScrollToTop from "./utils/ScrollToTop";
import "./components/FontawsomeIcons";

export default function App() {
    const auth = useAuth();

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
        default:
            break;
    }

    if (auth.isLoading) {
        auth.clearStaleState();
        return <Loader />;
    }

    if (auth.error && auth.error.message !== "access_denied") {
        console.error(auth.error.message);
        return <div>Oops... {auth.error.message}</div>;
    }

    return (
        <>
            <UnauthenticatedTemplate>
                <Router>
                    <ScrollToTop />
                    <Layout>
                        <Routes>
                            
                            {/*Public pages*/}
                            <Route exact path="/" element={<Home />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/terms-and-conditions" element={<Terms />} />
                            <Route path="/accessibility-statement" element={<Accessibility />} />
                            <Route path="/cookie-policy" element={<Cookies />} />
                            <Route path="/marketplace" element={<Marketplace />} />
                            <Route path="/news" element={<News />} />
                            <Route path="/buyers" element={<Buyers />} />
                            <Route path="/suppliers" element={<Supplier />} />
                            <Route path="/marketplace/:supplierName" element={<IndividualSuppliers />} />
                            <Route path="/events" element={<Events />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/opportunities" element={<Opportunities />} />
                            <Route path="/prices" element={<Prices />} />
                            <Route path="/membership" element={<Membership />} />
                            <Route path="/public" element={<Public />} />
                            <Route path="/research" element={<Research />} />
                            <Route path="/accreditations" element={<Accreditations />} />
                            <Route path="/loginRedirect" element={<LoginRedirect />} />
                            <Route path="/registerUser/:accountId" element={<RegisterUser auth={auth} />} />
                            <Route path="*" element={<PageNotFound />} />

                            {/*Prevent navigation to Private pages if not logged on*/}
                            <Route path="/dashboard/*" element={<Home />} />
                        </Routes>
                    </Layout>
                </Router>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <Router>
                    <ScrollToTop />
                    <Layout>
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/about-us" element={<AboutUs />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/terms-and-conditions" element={<Terms />} />
                            <Route path="/accessibility-statement" element={<Accessibility />} />
                            <Route path="/cookie-policy" element={<Cookies />} />
                            <Route path="/marketplace" element={<Marketplace />} />
                            <Route path="/news" element={<News />} />
                            <Route path="/suppliers" element={<Supplier />} />
                            <Route path="/Marketplace/:supplierName" element={<IndividualSuppliers />} />
                            <Route path="/events" element={<Events />} />
                            <Route path="/sales" element={<Sales />} />
                            <Route path="/opportunities" element={<Opportunities />} />
                            <Route path="/buyers" element={<Buyers />} />
                            <Route path="/membership" element={<Prices />} />
                            <Route path="/public" element={<Public />} />
                            <Route path="/research" element={<Research />} />
                            <Route path="/accreditations" element={<Accreditations />} />
                            <Route path="/loginRedirect" element={<LoginRedirect />} />

                            {/*Prevent registration if they are logged on*/}
                            <Route path="/registerUser/:accountId" element={<Home />} />

                            {/*Private pages*/}
                            <Route path="/dashboard/*" element={<Dashboard />} />
                        </Routes>
                    </Layout>
                </Router>
            </AuthenticatedTemplate>
        </>
    );
}
