import { Link } from "react-router-dom";
import bannerImg from "../../assets/images/banners/g-banner-sml.jpg";
import { Header } from "../../components/Header/Header";
import styles from "./Terms.module.css"

export const Terms = () => {
    return (
        <>
            <Header text="Terms and Conditions" bgColour="#01402E" img={bannerImg} />
            <div className={styles.termsContainer}>
                <p>
                    The terms and conditions of use set out below (&#8220;Terms and Conditions&#8221;) apply to the Website owned,
                    operated and controlled by Us (as defined below), located on the World Wide Web at https://www.greeneconomy.co.uk/ (the &#8220;Website&#8221;).
                    By using the Website, you are deemed to have full knowledge of the following terms and conditions and to accept them.
                    If you do not agree to be bound by the following terms and conditions, please do not use the Website.
                </p>

                <p>
                    We are The Growth Company Limited [trading as Green Economy],
                    a company registered in England and Wales under company registration number 2443911.
                    Our registered office is at Lee House, 90 Great Bridgewater Street, Manchester M1 5JW (&#8220;We, Us or Our&#8221;).
                </p>
                <h4 className={styles.termsTitle}>Alteration to the Terms and Conditions</h4>
                <p>
                    We may, at any time change, modify, add to or remove part or all of these Terms and Conditions. You should therefore check these Terms and Conditions periodically to see if they have changed.
                    Your continued use of this Website following any change in the Terms and Conditions will be deemed to constitute acceptance by you of those changes.
                </p>

                <h4 className={styles.termsTitle}>Copyright and Intellectual Property Rights</h4>
                <p>
                    All material accessible on this Website is protected by copyright. You may view Website pages on screen and you may print or download extracts for your own personal use in accordance with these Terms and Conditions and in particular, the points listed below.
                </p>
                <p>You may supply a copy of any such extract to any third party provided that:</p>
                <ul>
                    <li>
                        the extract is for their own personal use;
                    </li>
                    <li>
                        the extract is not supplied as part of or incorporated in another work, website or publication;
                    </li>
                    <li>
                        the extract is not supplied either directly or indirectly in return for commercial gain; and
                    </li>
                    <li>
                        the third party is made aware that the source of the copy is this Website and that these Terms and Conditions apply equally to them.
                    </li>
                </ul>
                <p>No part of this Website may be reproduced, transmitted to, or stored on any other Website or in any other form of electronic medium without Our express written consent.</p>
                <p>The intellectual property rights in the Website and in any text, images, video, audio or other multimedia content, software or other information or material submitted to or accessible from the Website (&#8220;Content&#8221;) are owned by Us and Our licensors.</p>
                <p>We and Our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world.
                    This means, for example, that We remain owners of them and are free to use them as We see fit.
                </p>
                <p>Nothing in these Terms and Conditions grants you any legal rights in the Website or the Content other than as necessary for you to access it.
                    You agree not to adjust, try to circumvent or delete any notices contained on the Website or the Content and in particular, in any digital rights or other security technology embedded or contained within the Website or the Content.</p>

                <h4 className={styles.termsTitle}>No text or data mining, or web scraping </h4>
                <p>You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site or any services provided via,
                    or in relation to, our site. This includes using (or permitting, authorising or attempting the use of):</p>
                <ul>
                    <li>
                        Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site or any data, content, information or services accessed via the same.
                    </li>
                    <li>
                        Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.
                    </li>
                </ul>
                <p>The provisions in this clause should be treated as an express reservation of Our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive ((EU) 2019/790).
                </p>
                <p>This clause shall not apply insofar as (but only to the extent that) We are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to Us.
                </p>

                <h4 className={styles.termsTitle}>Continuity of Service</h4>
                <p>We will use Our reasonable endeavours to provide you with a prompt and continuing service. We do not however warrant that the service We provide will continue uninterrupted,
                    be available at all times or without delay or that it will remain unchanged.
                    In particular We reserve the right to bring the Website down as and when We consider the same to be necessary or desirable for the purposes of maintenance.</p>
                <p>We make no promise that the Website is appropriate or available for use in locations outside of the UK.
                    If you choose to access the Website from locations outside the UK, you acknowledge you do so at your own initiative and are responsible for compliance with local laws where they apply. 
                </p>

                <h4 className={styles.termsTitle}>Indemnity</h4>
                <p>In using the Website, you indemnify Us against any actions, claims, demands or other proceedings brought by any third party against Us as a consequence of your use of the site.</p>

                <h4 className={styles.termsTitle}>Quality of Service</h4>
                <p>Whilst We will use Our reasonable endeavours to ensure that the information contained in the Website is correct and reliable, no warranty, either express or implied, is given as to the accuracy or completeness of that information.
                    Furthermore, We cannot promise that the Website will be fit or suitable for any purpose nor free from any bugs. Any reliance that you may place on the information on the Website is at your own risk.</p>
                <p>Any Content on the Website is provided for your general information purposes only and to inform you about Us and Our products and news, features, services and other websites that may be of interest,
                    but has not been tailored to your specific requirements or circumstances. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on for any purposes.
                    You should always use your own independent judgement when using Our Website and its Content.
                </p>
                <p>
                    While We try to make sure that the Website is secure, We do not actively monitor or check whether information supplied to Us through the Website is confidential, commercially sensitive or valuable. 
                </p>

                <h4 className={styles.termsTitle}>Links to External Sites</h4>
                <p>The Website contains hypertext links to a number of websites owned, operated and controlled by third parties. Any such hypertext links or references are provided for your convenience only.
                    We have no control over or proprietary interest in any of these websites and accept no legal responsibility for any content, material or information contained in them and, as such,
                    we do not make any warranties with regard to the quality, security, accuracy or any other aspects of such sites, and exclude any and all liability arising from use of the same.
                    The display of any hypertext links and reference to any third party advertising or website does not mean that We endorse that third party's website, products or services.
                    Your use of a third party website may be governed by the terms and conditions of that third-party website and is at your own risk.
                </p>

                <h4 className={styles.termsTitle}>Third Party Contributors</h4>
                <p>You acknowledge that many parts of the service provided on this Website are provided by third party service providers ("TSPs") and not by Us.
                    You agree that We shall bear no responsibility to you in respect of any aspect of any third party services or contributions provided by TSP's and that your only remedy for failure to provide such services is a remedy against the relevant TSP.
                </p>
                <p>Where appropriate the provision of any third party services to you by any TSP shall be provided to you on the terms and conditions of the relevant TSP. It is your responsibility to make yourself aware of such terms and conditions.
                </p>
                <p>
                    Acceptance by you of any services provided by an TSP may form a contract between you and the TSP directly.
                    It is your responsibility to check whether acceptance of such services will be construed as confirming your total and unequivocal acceptance of the TSP's terms and conditions of service.
                </p>
                <h4 className={styles.termsTitle}>Acceptable Use Policy</h4>
                <p>In accessing or using the Website or otherwise indicating your consent you are deemed to have read Our Acceptable Use Policy (Policy) and agree to be bound by this Policy.
                    For the avoidance of any doubt, this Policy is covered in point 1-6 below and contains the rules governing how you may access and use this Website and all associated web pages.
                </p>
                <p>The Policy forms part of these Terms and Conditions. you should therefore read this Policy carefully before using the Website. If you do not agree with or accept any part of this Policy, you should stop using the Website immediately.
                </p>
                <div className={styles.boxHeader}>
                    <h4 className={styles.termsTitle}>ACCEPTABLE USE POLICY</h4>
                </div>
                <div className={styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        1. General
                    </div>
                    <div className={styles.boxText}>
                        <p>This Policy provides only general guidance with regard to the use of the Website by you.</p>
                        <p>We permit you to use the Website only for accessing information about Us and, if there are interactive tools on the Website, communicating with us.
                            The use of the Website in any other way, including any unacceptable use set out in this Policy or in any manner that disrupts the operation of the Website or business of the website or business of any other entity or person,
                            is not permitted.</p>
                    </div>
                </div>
                <div className={styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        2. Copyright
                    </div>
                    <div className={styles.boxText}>
                        <p>(a) If there is the requisite facility on the Website and you place material, including software, on the Website, you will automatically grant to Us the right, at no cost, to copy, adapt, edit, publish, distribute,
                            translate and otherwise use all such material and to grant to our other customers the right to print copies of the material for their own use. Subject to this grant and point 3(l) below, you will retain all rights in the material.</p>
                        <p>(b) You may not place copyright material on the Website without the written permission of the owner of the copyright or of some person authorised by the owner to give such consent.
                            You must, if requested to do so by Us, provide written proof of such consent.
                        </p>
                    </div>
                </div>
                <div className={styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        3. Content
                    </div>
                    <div className={styles.boxText}>
                        <p>As a condition of your use of the Website, you agree to the following:</p>
                        <p>(a) When posting any advertisement or other commercial solicitation or material to any special interest group or site it is your obligation to comply with all applicable laws. In particular,
                            the advertisement or commercial solicitation must comply with all relevant legislation statutes, regulations and codes in any relevant jurisdiction.</p>
                        <p>(b) Posting private or other confidential material to any special interest group is prohibited.</p>
                        <p>(c) Impersonating another business or person or otherwise falsifying your business or other name in any post to any group is prohibited.</p>
                        <p>(d) Any communication or posting which may be perceived or construed by Us to be harassment, embarrassment, annoyance or offence of a third party is prohibited as is the use of the Website for sending computer viruses, malware or the like.</p>
                        <p>(e) You must not publish on the Website any material which infringes the rights of any person (including, without limitation, their copyright, trademark, confidential information or other intellectual property rights) or which is,
                            in Our sole and absolute opinion, abusive, blasphemous, obscene, pornographic, sexually explicit, defamatory (or likely to give rise to an allegation of defamation), vitriolic, offensive, discriminatory, deceptive,
                            menacing in character, illegal, deliberately inflammatory or likely to encourage unlawful behaviour. We consider the publication of such materials and other inappropriate materials to be severe abuses of the Website.
                        </p>
                        <p>(f) You must not use the Website to perform or to solicit the performance of any illegal activity or any activity that is contrary to generally accepted convention or is intended to solicit membership or
                            sale of any services competing with the Website.
                        </p>
                        <p>(g) Promoting unsolicited advertisements, the sending of spam or the simulation of communications from Us or another service or entity in order to collect identity information,
                            authentication credentials or other information (phishing) is not prohibited.
                        </p>
                        <p>(h) We reserve the right in Our sole and absolute discretion to edit or delete any material on the Website without prior notice whether or not it violates any of the standards set out in this Policy.</p>
                        <p>(i) Where applicable, you must ensure that you have been provided with the necessary consent of any third party and all materials submitted must be factually accurate or your own genuinely held belief.</p>
                        <p>(j) You agree not to use the Website to commit any act of fraud, in any manner that harms or is likely to harm minors, to gain unauthorised access to or use of computers,
                            data, systems, accounts or networks or to attempt to circumvent password or user authentication methods.
                        </p>
                        <p>(k) You will not add Content that is likely to damage the reputation of Us or any part of the Wider Group (as defined under &#8220;Your privacy and personal information&#8221; section below).</p>
                        <p>(l) By submitting any material to the Website, you grant to Us a royalty-free, irrevocable, non-exclusive transferable licence to use, reproduce, modify, publish, edit, translate, distribute,
                            perform and display the material (in whole or in part) on the Website and on any other websites operated by Us, indefinitely.</p>
                    </div>
                </div>
                <div className={styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        4. Using Our name and logo
                    </div>
                    <div className={styles.boxText}>
                        <p>You may not use Our trade marks, logos or trade names except in accordance with this Policy and the Terms and Conditions.</p>
                    </div>
                </div>
                <div className={styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        5. Consequences of ignoring the Policy 
                    </div>
                    <div className={styles.boxText}>
                        <p>(a) If We in Our sole discretion consider that you are in breach of any of this Policy then We may take such action as We, in Our sole discretion, consider appropriate.
                            This may include (but shall not be limited to), the editing or deletion of any material appearing on the Website or the suspension or termination of your rights of access to any service.
                            This may also include the disclosure of any material to law enforcement authorities or the taking of any action that We consider in Our sole discretion to be necessary to remedy the breach.
                            You will not be entitled to any credit or compensation for any loss of access to any service which results from any such action taken by Us under this Policy.</p>
                        <p>(b) You accept that We give no undertaking to you that We will take any action to ensure compliance with the Policy by any other customer and that We are entitled to take such action as
                            We consider appropriate under the Policy or to refrain from doing so at Our absolute discretion.
                        </p>
                        <p>(c) Whilst We do not have any obligation to monitor the Website We reserve the right to prohibit activities which in Our absolute judgement may cause harm of any kind to other customers, third parties, the Website or Us.
                        </p>
                    </div>
                </div>
                <div className={`mb-4 ` + styles.boxPanels}>
                    <div className={styles.boxNumerals}>
                        6. Chat rooms and other interactive services 
                    </div>
                    <div className={styles.boxText}>
                        <p>(a) We may make bulletin boards, membership areas, payment provisions, chat rooms or other communications services (&#8220;Interactive Services&#8221;) available on the Website.</p>
                        <p>(b) We are not obliged to monitor or moderate any text, images, video, audio or other multimedia content, information or material submitted to Our Interactive Services.
                            The views expressed by other users on Our Website do not represent Our views or values.
                        </p>
                        <p>(c) Generally, we will not share your personal details with other members of the Interactive Services without your express consent.
                            You may choose to allow other members to see your name and/or email address when you join the Interactive Services and you may change your settings at any time under the Profile section.
                        </p>
                        <p>(d) You take full responsibility for the Content you add to the Interactive Services.</p>
                    </div>
                </div>
                <h4 className={styles.termsTitle}>Your Responsibilities</h4>
                <p>You must ensure that at all times you observe these Terms and Conditions and the Acceptable Use Policy. You may, from time to time, when you are using the Website, be asked to provide or be
                    provided with a password to enable you to access certain services. You must, at all times keep your password private and confidential and you may not permit third parties to use your password.
                </p>
                <p>You agree that you are solely responsible for all costs and expenses you may incur in relation to your use of the Website.</p>
                <h4 className={styles.termsTitle}>Queries</h4>
                <p>While every effort is made to ensure that any enquiry you submit is dealt with promptly, We cannot guarantee that your query will always be dealt with within the time-scale you require.</p>
                <p>If your enquiry is submitted to a TSP linked to the Website and operating through this Website then Our staff will not necessarily read or see your enquiry. We cannot guarantee complete
                    confidentiality and recommend that you do not include personal or sensitive information, or information which could be classed as a trade secret, via the Internet.</p>
                <h4 className={styles.termsTitle}>Registration and Password Security</h4>
                <p>Use of the Website may require registration, particularly in order to access restricted areas of the Website.</p>
                <p>We are not obliged to permit anyone to register with the Website and We may refuse, terminate or suspend registration to anyone at any time.</p>
                <p>You are responsible for making sure that your password and any other account details are kept secure and confidential.</p>
                <p>If We have reason to believe there is likely to be a breach of security of misuse of the Website through your account or the use of your password,
                    We may notify you and require you to change your password, or We may suspend or terminate your account.</p>
                <p>Any personal information you provide to Us as part of the registration process will be processed in accordance with Our&nbsp;
                    <Link className="PLinkTo" as={Link}
                        to="/privacy"
                        href="/privacy">Privacy Policy</Link>.
                </p>
                <h4 className={styles.termsTitle}>Your privacy and personal information</h4>
                <p>Your privacy and personal information are important to Us. Any personal information that you provide to Us will be dealt with in line with Our&nbsp;
                    <Link className="PLinkTo" as={Link}
                        to="/privacy"
                        href="/privacy">Privacy Policy</Link>,
                    which explains what personal information We collect from you, how and why We collect, store, use and share such information, your rights in relation to your personal information and how to
                    contact Us and supervisory authorities in the event you have a query or complaint about the use of your personal information.
                </p>
                <p>You acknowledge and accept that you may be providing us with commercially sensitive information; this information may then be used by our Wider Group (as defined below) including Our employees, officers,
                    representatives or advisers as it will be placed into Our Customer Relationship Management System (CRM) which is accessible to members of the Wider Group.</p>
                <p>Our Wider Group includes the entities listed under the Company Information tab of our Website and can be found using the following link&nbsp;
                    <Link className="PLinkTo" as={Link}
                        to="/about-us"
                        href="/about-us">About us</Link>.</p>
                <h4 className={styles.termsTitle}>Complaints</h4>
                <p>We will try to resolve any disputes with you quickly and efficiently.If you are unhappy with Us, please see Our <a className="PLinkTo" target="_blank" rel="noopener noreferrer" href="https://www.growthco.uk/about-us/policies/customer-complaints-policy/"> Complaints Policy</a>.</p>
                <h4 className={styles.termsTitle}>Disclaimer</h4>
                <p>We shall not be liable for any claims or losses which are due to circumstances beyond Our reasonable control. We shall not be liable to you for any claims, damage or losses that was not foreseeable,
                    any loss or damage not caused by Our breach or negligence, or any business loss or damage (including profits or reputational). By foreseeable We mean that, at the time these Terms and Conditions were formed,
                    it was either clear that such loss or damage would occur or you and Us both knew that it might reasonably occur, as a result of something We did (or specifically failed to do).
                </p>
                <p>If any provision of these Terms and Conditions (or part of any provision) is or becomes illegal, invalid or unenforceable, the legality, validity and enforceability of any other provision of the Terms and Conditions shall not be affected.</p>
                <p>If any provision of these Terms and Conditions (or part of any provision) is or becomes illegal, invalid or unenforceable but would be legal, valid and enforceable if some part of it was deleted or modified, the provision or part-provision
                    in question shall apply with the minimum such deletions or modifications as may be necessary to make the provision legal, valid and enforceable. </p>
                <p>This disclaimer does not affect your statutory rights (if any) and no part of these Terms and Conditions seeks to avoid liability for fraud, fraudulent misrepresentation or for negligence causing personal injury or
                    death or any other liability that the law does not allow Us to exclude or limit.
                </p>
                <p>We are not liable to you if We fail to comply with these Terms and Conditions because of circumstances beyond Our reasonable control.</p>
                <h4 className={styles.termsTitle}>General</h4>
                <p>No one other than Us or you has any right to enforce any of these Terms and Conditions.</p>
                <p>In the event that a contract is duly entered into between Us and you and any provisions of that said contract at any time conflict with any provisions of these Terms and Conditions, the provisions of the contract shall prevail.</p>
                <p>We may transfer Our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and We will ensure that the transfer will not affect your rights under these terms.</p>
                <p>These Terms and Conditions are governed by and shall be construed in accordance with English law. Any disputes will be subject to the non-exclusive jurisdiction of the courts of England and Wales. </p>
                <h4 className={styles.termsTitle}>Online Transactions</h4>
                <p>Transaction payments for services offered through this Website are usually dealt with by third party payment providers. The third party payment providers may have their own terms and conditions.
                    Please therefore carefully review these before entering your payment details. Please see the &lsquo;Third Party Contributors&rsquo; section above. Online bookings will usually be processed through third party providers.</p>
                <h4 className={styles.termsTitle}>Booking, Cancellation and Transfer Policy</h4>
                <ul>
                    <li>We reserve the right to change a workshop, event or programme, presenter, venue and alter or cancel published dates without liability.
                        Provisional bookings cannot be accepted and bookings will only be confirmed on receipt of the signed booking form or internet booking or on receipt of payment.</li>
                    <li>You can transfer to another workshop, event or programme up to the same monetary value free of charge at Our sole discretion;</li>
                    <li>Substitute delegates can be made at Our sole discretion. </li>
                    <li>Full refunds/transfers will be given at Our sole discretion.</li>
                    <li>All cancellations and transfers must be e-mailed to <a className="PLinkTo" href="mailto:info@greeneconomy.co.uk">info@greeneconomy.co.uk</a> </li>
                    <li>If a booking is not cancelled or transferred and the delegate does not attend, full event fees remain payable.</li>
                    <li>Where a refund is available, any payment made using a credit or debit card through the payment system will be refunded back onto the same card.</li>
                </ul>
                <h4 className={styles.termsTitle}>Business Advice</h4>
                <ul>
                    <li>In the event of a customer supplying incorrect information relating to a company when ordering credit reports, no refunds will be issued by Us.</li>
                    <li>In the event of a customer supplying incorrect or insufficient information relating to mailing lists, no refunds will be issued by Us.</li>
                    <li>For all other services provided on the Website, refunds will be given at Our sole discretion.</li>
                </ul>
                <h4 className={styles.termsTitle}>Feedback</h4>
                <p>We hope that you will find Our Website informative and easy to use. We should be grateful to receive any comments or queries that you have concerning Our site,
                    or any suggestions as to how it could be improved. Please e-mail Us at <a className="PLinkTo" href="mailto:info@greeneconomy.co.uk">info@greeneconomy.co.uk</a> </p>
                <h4 className={styles.termsTitle}>See also:&nbsp;
                    <Link className="PLinkTo" as={Link}
                        to="/privacy"
                        href="/privacy">Privacy Policy</Link> and&nbsp;
                    <Link className="PLinkTo" as={Link}
                        to="/cookie-policy"
                        href="/cookie-policy">Cookie Policy</Link> 
                </h4>
            </div>
        </>
    );
};