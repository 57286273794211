import { useParams, Link } from "react-router-dom";

import { SupplierInfoSection } from "../../components/SupplierInfo/SupplierInfoSection";
import { SupplierDetailsSection } from "../../components/SupplierUSP/SupplierUSPSection";
import styles from "./IndividualSuppliers.module.css";

import { useAccountInfo } from "../dashboard/useAccountInfo";
import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";
import { Header } from "../../components/Header/Header";
import bannerImg from "../../assets/images/banners/m-banner-sml.jpg";

export const IndividualSuppliers = () => {
    const { supplierName } = useParams();
    const { supplier, isLoading, error } = useAccountInfo(supplierName);

    if (isLoading) {
        return <Loader data-testid="loader" page="supplier" />;
    }

    if (error) {
        return <Error error={error} id="individual-supplier-error" />;
    }

    return (
        <div>
            <Header text={supplier.account_Name} bgColour="#02BE7C" img={bannerImg} />
            <div className={styles.linkContainer}>
                <Link to="/marketplace" state={{ from: "supplier" }} className={styles.goBackDesktop}>
                    <span className="gcicon-left"></span>Back
                </Link>
            </div>
            <div className={styles.innerContainer}>
                <SupplierInfoSection supplier={supplier} />
                <SupplierDetailsSection supplier={supplier} />
            </div>
        </div>
    );
};
