import { Link } from "react-router-dom";
import { savePageNumberToLocalStorage, truncateString } from "../../utils";
import verified from "../../assets/images/Business/Verified.png";
import style from "./marketplace.module.css";


export const CompanyLine = (props) => {


    return (
        <div className={style.dlistBody}>
            <div className={style.dlistTitleContainer}>
                <h5 className={style.dlistTitle}>{props.title}</h5>
                {props.distance && (
                    <p className={style.dlistDistance}>{parseFloat(props.distance).toFixed(1)}&nbsp;miles</p>
                )}
            </div>
            {props.verified && (
                <div>
                    <img src={verified} alt="Verified by GE" />
                </div>
            )}

            <h6 className={style.dlistSummary}>
                <p>{truncateString(props.description)}</p>
            </h6>
            <Link
                className={style.companyLink}
                to={`${props.title}`}
                onClick={() => savePageNumberToLocalStorage(props.activePage)}
            >
                Find out more
                <span className="gcicon-right" />
            </Link>
        </div>
    );
};
