import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { oidcConfig } from "./oidcConfig";
import { AuthProvider } from "react-oidc-context";

ReactDOM.render( 
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <App />
        </AuthProvider>
    </React.StrictMode>,
  document.getElementById('root')
);


