
import styles from "./social.module.css";

const Social = () => {
    return (
        <>
            <h5 style={{ textAlign: 'center' }}>Follow us on social media for the latest news</h5>
            <div className={styles.socialsection}>
                <a className={styles.facebook} href="https://www.facebook.com/Green-Economy-108275631897116" title="Green Economy Facebook" target="_blank" rel="noopener noreferrer"><i className="gcicon-facebook"></i></a>
                <a className={styles.twitter} href="https://twitter.com/UKGreenEconomy" title="Green Economy Twitter" target="_blank" rel="noopener noreferrer"><i className="gcicon-twitter"></i></a>
                <a className={styles.linkedin} href="https://www.linkedin.com/company/greeneconomyuk/" title="Green Economy LinkedIn" target="_blank" rel="noopener noreferrer"><i className="gcicon-linkedin"></i></a>
            </div>
        </>
        )
}

export default Social;