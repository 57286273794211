import { useForm } from "react-hook-form";

import Accordion from "react-bootstrap/Accordion";

import styles from "../Profile.module.css";
import Button from "../../../components/Button/Button.jsx";

export const CompanyDetails = ({
    supplier,
    onClick,
    accType,
    busType,
    tradeAssocMembership,
    activeAccordion,
    nextAccordionSection,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            accType: "",
            busType: "",
            vatNum: "",
            memType: "",
            tradeAssType: "",
        },
        mode: "onChange",
    });

    const { account_Name, business_Overview, email, contactName } = supplier;

    setValue("accountName", account_Name);
    setValue("businessOverview", business_Overview);
    setValue("primaryContact", contactName);
    setValue("primaryContactEmail", email);

    const handleNextAccordion = () => {
        onClick(nextAccordionSection);
    };

    return (
        <Accordion>
            <Accordion.Item eventKey="1" className={styles.sectionContainer}>
                <Accordion.Header style={{ fontFamily: "inherit", fontWeight: "normal" }}>
                    Company details
                </Accordion.Header>
                <Accordion.Body className={styles.formContainer}>
                    <form action="" onSubmit={handleSubmit((data) => data)}>
                        <div>
                            <label htmlFor="accountName">Company name:</label>
                            <input type="text" {...register("accountName")} id="" placeholder="Company name" disabled />
                            {errors.firstName && <p className={styles.error}>{errors.firstName.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="businessOverview">Business overivew:</label>
                            <textarea
                                type="text"
                                {...register("businessOverview", { required: "This field is required" })}
                                id=""
                                placeholder="Business overivew"
                            />
                            {errors.lastName && <p className={styles.error}>{errors.lastName.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="accType">Account type:</label>
                            <select
                                name="accType"
                                className={styles.dropdownBtn}
                                {...register("accType", { required: true })}
                            >
                                <option value="" disabled>
                                    Please select an account type:
                                </option>
                                {accType &&
                                    accType.map((type) => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="vatNum">VAT No:</label>
                            <input
                                type="text"
                                {...register("vatNum", { required: "This field is required" })}
                                id=""
                                placeholder="VAT number"
                            />
                            {errors.lastName && <p className={styles.error}>{errors.lastName.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="busType">Type of business:</label>
                            <select
                                {...register("busType", { required: "This field is required" })}
                                name="busType"
                                className={styles.dropdownBtn}
                            >
                                <option value="" disabled>
                                    Please select a business type:
                                </option>
                                {busType &&
                                    busType.map((type) => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="memType">Member type:</label>
                            <select
                                {...register("memType", { required: "This field is required" })}
                                name="memType"
                                className={styles.dropdownBtn}
                            >
                                <option value="" disabled>
                                    Please select a member type:
                                </option>
                                <option value="individual">Individual</option>
                                <option value="corporate">Corporate</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="tradeAssType">Trade association membership:</label>
                            <select
                                {...register("tradeAssType", { required: "This field is required" })}
                                name="tradeAssType"
                                className={styles.dropdownBtn}
                            >
                                <option value="" disabled>
                                    Please select a member type:
                                </option>
                                {tradeAssocMembership &&
                                    tradeAssocMembership.map((type) => {
                                        return (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="primaryContact">Primary contact name:</label>
                            <input
                                type="text"
                                {...register("primaryContact")}
                                id=""
                                placeholder="Contact's name"
                                disabled
                            />
                            {errors.primaryContact && <p className={styles.error}>{errors.primaryContact.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="primaryContactNumber">Primary contact number:</label>
                            <input
                                type="text"
                                {...register("primaryContactNumber")}
                                id=""
                                placeholder="Contact's number"
                                disabled
                            />
                            {errors.primaryContactNumber && (
                                <p className={styles.error}>{errors.primaryContactNumber.message}</p>
                            )}
                        </div>
                        <div>
                            <label htmlFor="primaryContactEmail">Primary contact email:</label>
                            <input
                                type="text"
                                {...register("primaryContactEmail")}
                                id=""
                                placeholder="Contact's email"
                                disabled
                            />
                            {errors.primaryContactEmail && (
                                <p className={styles.error}>{errors.primaryContactEmail.message}</p>
                            )}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flex: "1" }}>
                            <Button
                                color="Purple"
                                text="Next"
                                onClick={handleNextAccordion}
                                height="50px"
                                width="200px"
                                name="personal details"
                                type="submit"
                                arrow={true}
                            />
                        </div>
                    </form>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
