import { useState } from "react";

import Accordion from "react-bootstrap/Accordion";

import Button from "../../../components/Button/Button.jsx";

import styles from "../Profile.module.css";

export const CompanyLogo = ({ supplier, onClick }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    return (
        <Accordion>
            <Accordion.Item eventKey="0" className={styles.sectionContainer}>
                <Accordion.Header style={{ fontFamily: "inherit", fontWeight: "normal" }}>
                    Company Logo
                </Accordion.Header>
                <Accordion.Body>
                    <div>
                        {selectedImage ? (
                            <div className={styles.logoContainer}>
                                <div className={styles.imageContainer}>
                                    <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                                </div>
                                <Button
                                    color="Purple"
                                    text="Remove image"
                                    disabled={false}
                                    onClick={() => setSelectedImage(null)}
                                    height="50px"
                                    width="200px"
                                    name="removeImage"
                                    type=""
                                    arrow={false}
                                />
                            </div>
                        ) : (
                            <div className={styles.logoContainer}>
                                <div className={styles.imageContainer}>
                                    <img
                                        src={`data:image/png; charset=ISO-8859-1;base64,${supplier.companyLogo}`}
                                        alt=""
                                    />
                                </div>
                                <p>Drag and drop to upload</p>
                            </div>
                        )}
                        {!selectedImage && (
                            <div className={styles.logoContainer}>
                                <input
                                    type="file"
                                    name="myImage"
                                    className={styles.customFileInput}
                                    onChange={(event) => {
                                        setSelectedImage(event.target.files[0]);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            text="Next"
                            disabled={false}
                            onClick={onClick}
                            height="50px"
                            width="200px"
                            arrow={true}
                        />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};
