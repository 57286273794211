import React from "react";
import { useState, useEffect } from "react";
import { PageCard } from "../../components/PageCard/PageCard";
import MailModal from "../../components/MailModal/EnquiryModal";
import { setDescription, setTitle } from "../../utils";

import Banner from "../../components/Banner/Banner";
import bannerImg from "../../assets/images/banners/GE-Banner.png";

import sales from '../../assets/images/pageIcons/Sales.png';
import companies from '../../assets/images/pageIcons/Companies.png';
import tenders from '../../assets/images/pageIcons/Tenders.png';

import rni from '../../assets/images/misc/RnI.png';
import grow from '../../assets/images/misc/Grow.jpg';
import engineering from '../../assets/images/misc/engineer.jpg';
import deliverresults from '../../assets/images/misc/DeliveringResults.jpg';
import gm from '../../assets/images/misc/GM.png';

import styles from "./public.module.css";

const Public = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setTitle("Green Economy | Public sector: Green growth, deliver local Net Zero targets");
        setDescription("Grow your green economy, services for local authorities and city-regions, to grow the local Low Carbon and Environmental Goods Sector")
    }, []);

    return (
        <>
            <Banner
                title="GROW YOUR GREEN ECONOMY"
                src={bannerImg}
                alt="Public Sector"
                text="UNDERSTAND AND GROW YOUR LOCAL SUPPLY CHAIN"
                subText="TO DELIVER YOUR NET ZERO TARGETS"
                color="white"
                bgcolour="#02be7c"
            />

            <div className={styles.publicContainer}>
                <h2>Grow your green economy - our services for local authorities and city-regions</h2>
                <p className="mb-3 FirstPara">
                    Across the county the impacts of climate change are already being felt with an increased risk of flooding, temperature rises and associated water shortages effecting their homes, businesses, wildlife, and green spaces.
                    Local Authorities across the country have declared climate change and biodiversity crises in their local area.Regions across the UK are on a trajectory to meet net zero emissions by at least 2050
                    to mitigate these impacts and secure a successful transition to a more sustainable and greener economy. 
                </p>
                <p className="mb-2">
                    Green Economy works with local partners to deliver their transition to net zero, by analysing and developing the local green supply chain and providing support to businesses on their journey to net zero. 
                </p>
                <h3>Our services</h3>
                <div className={styles.publicCardContainer}>
                    <PageCard
                        width="100%"
                        header="Green sector mapping and insights"
                        text="We provide intelligence on your local green economy, analysing the capabilities, size and scale of the local green supply chain to identify opportunities for green tech and barriers impacting adoption at a local level. 
                                This vital data can then inform economic development planning on your transition to net zero. "
                        hasLogo={true}
                        logo={rni}
                        styling="imageFour"
                    />
                    <PageCard
                        width="100%"
                        header="Connecting buyers to local suppliers "
                        text="We increase local uptake of green technology by increasing visibility to the supply chain and demystifying the installation process for buyers.
                            Local green businesses add their profile to our Marketplace showcasing their products and services and access bespoke sales opportunities from direct buyers or brokered projects through Green Economy."
                        hasLogo={true}
                        logo={engineering}
                        styling="imageFour"
                    />
                    <PageCard
                        width="100%"
                        header="Green technologies sector scale and growth"
                        text="We help businesses to identify growth potential with a sector-specialist diagnostic, action plan, and tailored support package delivered by a specialist business advisor, with a purpose to embed the knowledge,
                            skills and accreditations required to increase competitiveness. "
                        hasLogo={true}
                        logo={grow}
                        styling="imageFour"
                    />
                    <PageCard
                        width="100%"
                        header="Delivering results locally and nationally"
                        text="Green Economy  is a key driver in the transition to net zero providing a wholistic approach to decarbonising existing infrastructures, 
                            overcoming the market failures associated with the low uptake of green technologies and increasing the visibility and competitiveness of the sector. "
                        hasLogo={true}
                        logo={deliverresults}
                        styling="imageFour"
                    />
                </div>
                <div className={styles.impact}>
                    <div className={styles.impactImage}>
                        <img src={gm } alt="impact"/>
                    </div>
                    <div className={styles.impactText }>
                        <h2>Our impact</h2>
                        <p className="mb-2" style={{ padding: '15px' }}><i>&#8220; Green Economy has played a key role in helping Greater Manchester grow the UK's second largest green technologies and services sector,
                            behind only London and the South East.The team is uniquely placed to support these businesses, understanding the nuances of this vast and evolving sector,
                            whilst also having specialist understanding of the challenges at play at a local level to develop support programmes that turn policy into a reality.&#8221;</i></p>
                        <p style={{ padding: '10px' }}>Mark Atherton, Director of Environment, Greater Manchester Combined Authority</p>
                    </div>
                    <div className={styles.ImpactCardContainer}>
                        <div className={styles.publicCard}>
                            <PageCard
                                width="100%"
                                header="More than &#163;11m in green sales brokered"
                                page="buyers"
                                hasLogo={true}
                                logo={sales}
                                styling="lightNoAction"
                            />
                        </div>
                        <div className={styles.publicCard}>
                            <PageCard
                                width="100%"
                                header="&#163;1.6bn market opportunities shared to our members"
                                page="none"
                                hasLogo={true}
                                logo={tenders}
                                styling="lightNoAction"
                            />
                        </div>
                        <div className={styles.publicCard}>
                            <PageCard
                                width="100%"
                                header="more than 500 businesses supported to grow "
                                page="public"
                                hasLogo={true}
                                logo={companies}
                                styling="lightNoAction"
                            />
                        </div>
                    </div>
                </div>
                <MailModal show={show} handleClose={handleClose} title="Help" email="info@greeneconomy.co.uk" template={2} />
                <h5 className="mt-4">To speak to us about providing support for your local green economy <a href="#/" onClick={handleShow} className="PLinkTo"> submit an enquiry</a></h5>
            </div>
        </>
    )
}

export default Public;