
import PlaceHolder from "../../assets/images/placeholder.png";

import LM from "../../assets/images/advisory/Louise Marix Evans.jpg";
import MC from "../../assets/images/advisory/Marilyn Comrie.jpg";
import AG from "../../assets/images/advisory/Amer.jpg";
import GR from "../../assets/images/advisory/Grainne Regan.jpg";
import JS from "../../assets/images/advisory/Jonny Sadler.jpg";
import IR from "../../assets/images/advisory/Ian Rippin.jpg";
import SA from "../../assets/images/advisory/Steve Aughton.jpg";
import BC from "../../assets/images/advisory/BruceCruse.jpeg";

export const AdvisoryData= [
    {
        id: 'LM',
        img: LM,
        name: 'Louise Marix Evans - Chair',
        position: 'Director, Quantum Strategy & Technology Ltd',
        report: 'https://www.theccc.org.uk/publication/local-authorities-and-the-sixth-carbon-budget/',
        text: [
            'Louise has over 20 years experience tackling climate change at a strategic, policy and project level. She has been a director of climate change consultancy Quantum Strategy and Technology for over 12 years. Louise has supported businesses, local authorities, universities, campaign groups and community enterprises to deliver climate action and move towards a low carbon economy.',
            'Her research and analysis work focuses on what we can practically do to accelerate the Net Zero transition and grow a resilient, inclusive and successful economy. Her Service Design work explores what we can do to make that transition easier for everyone.',
            "Louise was a Senior Advisor to the Climate Change Committee (CCC), which advises Government, during 2020 and 2022. She researched and wrote Local Authorities and the Sixth Carbon Budget report (see link) published alongside the Sixth Carbon Budget. Her briefing Climate Conversation: Delivering a Net Zero, Climate Resilient UK for the CCC will come out in late June 2022, describing the CCC's visits to ten local areas."
        ]
    },
    {
        id: 'SA',
        img: SA,
        name: 'Steve Aughton',
        position: 'Head of Vertical Market Development, Siemens Smart Infrastructure (Siemens Plc)',
        linkedin: '',
        text: [
            "Having read History at Oxford Steve worked at BAE Systems in a number of HR & Training roles before joining Siemens in 2001. Over the last two decades he has been involved in delivering solutions to transform UK energy infrastructure and urban environment with a primary focus on decarbonisation. As Head of Sales for Transmission Solutions Siemens delivered grid solutions connecting over 3 gigawatts of offshore wind power to the UK grid, during which time he was also a non-Executive Board Member of Envirolink, a business support organisation for the low carbon and environmental sector, supporting growth for SME's in sustainability and environmental protection.",
            "Since 2018 Steve has worked for Siemens Smart Infrastructure focusing on connected energy systems, smart campuses and buildings. Working across the Smart Urban Communities, Utilities, Healthcare and Industry Sectors, Steve's role is to help customers to transition to net zero carbon operation through digitalisation and the appropriate deployment of technology.",
            "Steve's ambition to join the Green Economy Advisory Board stems from a deep and longstanding interest in the role of technology in decarbonisation and an ambition to replicate the success witnessed in UK renewable energy deployment into the built environment."
        ]
    },
    {
        id: 'MC',
        img: MC,
        name: 'Dr Marilyn Comrie OBE',
        position: 'Blair Project',
        linkedin: '',
        text: [
            "Marilyn is CEO of the Manchester Innovation Activities Hub (MIAH), the UK's first net zero industrialisation and electrification skills training centre dedicated to power electronics machines. MIAH drives to serve the transport, food and drink, and energy sectors in the North West.",
            "An award winning serial entrepreneur, green tech innovator and strategic thinker, Marilyn is passionate about growing the diverse talent pipeline to plug net zero skills gaps. She sits on the board of the Greater Manchester Local Enterprise Partnership, the governance committee for the National Electrification Skills Forum, and is director of award winning e-motorsport STEM education provider the Blair Project.",
            "Marilyn's decision to join the Green Economy Advisory Board is born out of her desire to ensure greater inclusion and diversity within the clean growth sector. Green Economy has the potential to be a gamechanger for Greater Manchester, and a catalyst for kickstarting the Green Industrial revolution in Greater Manchester."
        ]
    },
    {
        id: 'AG',
        img: AG,
        name: 'Amer Gaffar',
        position: 'Manchester Fuel Cell Innovation Centre',
        linkedin: '',
        text: [
            "Amer is director of the Manchester Fuel Cell Innovation Centre at Manchester Metropolitan University and a sustainability/ low carbon professional with more than 20 years experience in the sector. Amer is a passionate advocate for fuel cell and hydrogen technology and has successfully built consensus and momentum in Greater Manchester and the North West towards positive environmental practice and cleaner energy over the last decade.",
            "In 2013, Amer founded the Greater Manchester Hydrogen Partnership to develop and build the strong and diverse network of SME, industry, researchers and public sector stakeholders integral to the work of the Centre today. In addition to his role at the Centre, Amer continues to lead a number of projects to make Greater Manchester a centre of excellence in hydrogen and fuel cells such as leading one of Europe's first targeted programme of Hydrogen Education (HySchools). He is leading the skills pathway for NetZero North West and led the development of the Greater Manchester Hydrogen and Fuel Cell Strategy."
        ]
    },
    //{
    //    id: 'SN',
    //    img: PlaceHolder,
    //    name: 'Samantha Nicholson',
    //    position: 'Manchester Climate Change Agency',
    //    linkedin: '',
    //    text: [
    //        'Samantha Nicholson'
    //    ]
    //},
    {
        id: 'GR',
        img: GR,
        name: 'Grainne Regan',
        position: 'Head of Partnerships, Octopus Energy',
        linkedin: '',
        text: [
            "Grainne has worked with Octopus since April 2020, leading partnerships with some of the most innovative organisations in the world. With a background in energy and automotive, Grainne has long been an advocate for decarbonisation. From large tech giants like Tesla, to smaller start-ups spearheading new technologies, at Octopus, Grainne has always looked for ways to partner with companies that are driving real change. Fundamental to the green energy transition are local players who will be instrumental in encouraging adoption of new green technologies at a community level.",
            "Having moved so quickly from start-up to scale up, Octopus recognises the importance of supporting and working with smaller businesses to drive change. Grainne has set up the 'Works with and Friends of Octopus' programmes to give tech manufacturers and installers the tools they need to talk about smart tariffs, fundamental in encouraging the transition to smart green tech.",
            "She's joined the Green Economy Advisory Board to help bring together the different components needed for a green and smart energy transformation and to learn from experts in the field to understand the blockers slowing processes down."
        ]
    },
    {
        id: 'BC',
        img: BC,
        name: 'Bruce Cruse',
        position: 'Director of Facilities England and Wales Cricket Board',
        linkedin: '',
        text: [
            "Bruce was born in Tasmania, starting his sporting career as a first-class cricketer which took him around the world, eventually to the UK where he has worked in sport for the last 26 years, 22 of those for the England and Wales Cricket Board. ",
            "He is currently the Director of Facilities and Infrastructure at ECB and his vision of a game-wide Facilities department responsible for supporting and investing in both the professional and recreational game, was realised in 2019. His main remit has been to modernise the sport and to create more access and opportunity to play. He has championed inclusive design principles, community engagement and sustainability throughout his career, supporting the development of sport-wide policy and guidance with a range of other governing bodies including Sport England. So far, he has raised and invested over 200M GBP supporting over 3,000 clubs and 25 stadiums.",
            "The ECB Facilities department under his direction are recognised as industry leaders. He's a great believer in exploring the 'gaps' and as a pioneer in the sport his focus is now on business transformation, tackling climate change, and delivering the new ECB Environmental, Social and Governance policy.",
            "Away from work he is a lover of art, history, and nature, he's often found on a long dog walk in the Pennines, traveling, or learning more about where we live and who we are."
        ]
    },
    {
        id: 'JS',
        img: JS,
        name: 'Jonny Sadler',
        position: 'Strategic Decarbonisation Manager, Electricity North West',
        linkedin: '',
        companysite: '',
        text: [
            "Jonny leads Electricity North West's work to help businesses play their part in the region's rapid transition to net zero. He works across ENWL and with external partners to ensure businesses have access to the support they need to adopt energy efficiency, electric vehicles, solar panels, batteries, heat pumps, and other low carbon technologies.",
            "Jonny was previously Deputy Director at Manchester Climate Change Agency and Environmental Strategy Manager at Manchester City Council."
        ]
    },
    {
        id: 'IR',
        img: IR,
        name: 'Ian Rippin',
        position: 'MCS',
        linkedin: '',
        text: [
            "Ian is CEO of MCS, a profit-for-purpose organisation that plays a central role in the UK's adoption of renewable energy sources for our homes and small businesses.",
            "MCS is responsible for the national Standards that cover low carbon products and their installation, used to produce electricity and heat from renewable sources.",
            "His career underpins his passion for quality and the role that Standards play in protecting consumers. Ian is currently leading MCS through a period of transformational change as the company strives to inspire a new generation of home-grown energy, fit for the needs of every UK home and community.",
            "He is a trusted voice and advisor on what needs to be done at a government and industry-level to sustain momentum in the home-grown energy market, which faces challenging times.",
            "Ian was appointed as a member of the Liverpool City Region Local Enterprise Partnership (LEP) Clean Growth Board in March 2021. He also supports Greater Manchester Mayor Andy Burnham as a member of the Greater Manchester Retrofit Taskforce."
        ]
    }
]
