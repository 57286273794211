import { useEffect, useState } from "react";

import { getCompanyDetailsFormFields } from "../../utils";

export const useCompanyDetailsFormFields = () => {
    const [formFields, setFormFields] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async function () {
            setIsLoading(true);
            try {
                const data = await getCompanyDetailsFormFields();
                if (data.code !== "SUCCESS") {
                    setError({ mesage: "Uh oh, looks like something when wrong" });
                } else {
                    setFormFields(data);
                }
            } catch (err) {
                console.error("Oh no I am an error in hook", err);
                setError({ mesage: "Uh oh, looks like something when wrong" });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return { formFields, isLoading, error };
};
