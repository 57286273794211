import { useState, useEffect } from "react";

import { getAccountInfo } from "../../utils";

export const useAccountInfo = (supplierName) => {
    const [supplier, setSupplier] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAccountInfo(supplierName);
                setSupplier(data);
                setIsLoading(false);
            } catch (error) {
                setError({ message: "Uh oh, looks like something went wrong" });
                setIsLoading(false);
            }
        };
        fetchData();
    }, [supplierName]);

    return {
        supplier,
        error,
        isLoading,
    };
};
