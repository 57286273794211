import { useState } from "react";

import { fetchSearchData, getSortByFromLocalStorage, sortMarketplaceSuppliers } from "../../utils";

export const useSearchData = () => {
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async (searchObject) => {
        try {
            setIsLoading(true);
            if (Object.keys(searchObject).length !== 0) {
                const data = await fetchSearchData(searchObject);
                const sortBy = getSortByFromLocalStorage();

                if (sortBy) {
                    const sortedSuppliers = sortMarketplaceSuppliers(data, sortBy);
                    setSearchResults(sortedSuppliers);
                } else {
                    setSearchResults(data);
                }
            }
        } catch (error) {
            setError({ message: "Uh oh, looks like something's went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    return {
        searchResults,
        error,
        isLoading,
        fetchData,
    };
};
