import { Routes, Route } from "react-router-dom";
import { useAccountInfo } from "./useAccountInfo";
import userProfile from "../../utils/userProfile";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { Overview } from "../overview/Overview";
import { Profile } from "../profile/Profile";
import { YourAccount } from "../account/YourAccount";

import styles from "./dashboard.module.css";
import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";

export const Dashboard = () => {
    const { supplier, isLoading, error } = useAccountInfo(userProfile.accountName);

    if (isLoading) {
        return <Loader page="dashboard" />;
    }

    if (error) {
        return <Error error={error} id="dashboard-error" />;
    }

    return (
        <div className={styles.container}>
            <Routes>
                <Route path="/" element={<Sidebar />}>
                    <Route index element={<Overview supplier={supplier} />} />
                    <Route path="overview" element={<Overview supplier={supplier} />} />
                    <Route path="profile" element={<Profile supplier={supplier} />} />
                    <Route path="account" element={<YourAccount supplier={supplier} />} />
                </Route>
            </Routes>
        </div>
    );
};
