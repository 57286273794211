import React, { useEffect, useState } from "react";
import styles from "./Team.module.css";
import { TeamData } from './TeamData';
import { AdvisoryData } from './AdvisoryData';
import {OverlayPageCard } from '../OverlayPageCard/OverlayPageCard'

export const TeamView = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        setData(TeamData)
    }, []);

    return (
        <>
            <h2>Meet the team</h2>
            <div className={styles.teamContainer}>
                <div className={styles.teamCards}>

                    {data.map((member) =>
                        <OverlayPageCard key={member.id}
                            id={member.id}
                            name={member.name}
                            position={member.position}
                            text={member.text}
                            linkedin={member.linkedin}
                            img={member.img}
                            styling='Team'
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export const AdvisoryView = () => {

    const [AdData, setAdData] = useState([]);

    useEffect(() => {
        setAdData(AdvisoryData)
    }, []);

    return (
        <>
            <h2>Advisory Board</h2>
            <p>
                We're committed to delivering the best experience for our members and to advocate for the businesses and technologies that will deliver the transition to net zero.
            </p>
            <p>
                To help us do this, we've established an Advisory Board that will help us shape the journey.
            </p>
            <div className={styles.teamContainer}>
                <div className={styles.teamCards}>

                    {AdData.map((member) =>
                        <OverlayPageCard key={member.id}
                            id={member.id}
                            name={member.name}
                            position={member.position}
                            text={member.text}
                            linkedin={member.linkedin}
                            report={member.report}
                            companysite={member.companysite}
                            img={member.img}
                            styling='Advisory'
                        />
                    )}
                </div>
            </div>
        </>
    )
}