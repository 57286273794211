import bannerImg from "../../assets/images/banners/g-banner-sml.jpg";
import { Header } from "../../components/Header/Header";
import styles from "./Privacy.module.css"

export const Privacy = () => {
    return (
        <>
            <Header text="Privacy Policy" bgColour="#01402E" img={bannerImg} />
            <div className={styles.privacyContainer}>
                <h2 className={styles.privacyTitle}>Privacy Policy</h2>

                <p>We recognise the importance of the privacy and the security of your personal information and this privacy notice explains what we do to look after your personal data -</p>

                <p><a href="https://www.growthco.uk/media/32apmsxt/group-privacy-notice-version-2-feb-21final.pdf" target="_blank" rel="noreferrer" className={styles.link}>The Growth Company Group Privacy Notice <span className="gcicon-right"></span></a></p>

                <p>It tells you:</p>
                <ul>
                    <li>Where and how we collect your personal information</li>
                    <li>What we do with your information and how we keep it secure</li>
                    <li>What your privacy rights are and how the law protects you</li>
                    <li>How we look after your personal data</li>
                    <li>Your rights over any personal information we hold about you</li>
                    <li>How to contact us with any queries that you have</li>
                </ul>
            </div>
        </>
    );
};