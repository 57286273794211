import AH from "../../assets/images/team/Amy House.jpg";
import KH from "../../assets/images/team/Katherine Horne.jpg";
import VW from "../../assets/images/team/Vicky Wilding.jpg";
import JM from "../../assets/images/team/Jacqui Musson.jpg";
import TC from "../../assets/images/team/Thalia Czakowska.jpg";
import PS from "../../assets/images/team/Priya Sirpal.jpg";
import SH from "../../assets/images/team/Sam Hartley.jpg";
import TM from "../../assets/images/team/Tim Mooney.jpg";

import ASHH from "../../assets/images/team/Ash Hulme.jpg";
import BP from "../../assets/images/team/Ben Peace.jpg";
import LW from "../../assets/images/team/Lola Wiseberg.jpg";
import MS from "../../assets/images/team/Mark Shepherd.jpeg";

export const TeamData= [
    {
        id: 'AH',
        img: AH,
        name: 'Amy House',
        position: 'Director',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEbe6P8uW0ZGwAAAYHy3h5gtGmOKveXOI5SmtSXhWg5iDjYenTcv-pDL-ZExmozj22vPIZqzt-0mPq9VptwWzYrD0tfydgJgF-IbT8ASbIFYPOqQ6ujScmwM0skLMR5ddYxgFo=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Famy-house-cenv-mciwm-26062520%2F',
        text: [
            'Amy is a chartered environmentalist with two decades experience working with the businesses at the heart of the net zero transition.',
            'With an MSc in Environmental Monitoring, Modelling and she has a deep understanding of the technologies that make up the industry, the legislation that affects it and the businesses and people at its core.',
            "Amy has a unique understanding of the supply chain and advocates for the sector with buyers, local decision makers and national policy makers. Amy and her team have established Green Economy to improve the competitiveness of the supply chain, to overcome the market failures that prevent uptake of low carbon technology and to promote the businesses playing a key role in delivering the UK's net zero ambition."
        ]
    },
    {
        id: 'KH',
        img: KH,
        name: 'Katherine Burden',
        position: 'Senior Business Development Manager',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEhoWE7nXNzLAAAAYHzABmYLR6bR9hyH4mKL7zpuAnxu6Q-LOTQ9iedCO6m2QZOk-FmUxuEP2DOQaUYRAXbqHCupElJ2WiMCmIuizDUFPHwhiCP8MXcDoK9LpYagP9WPWPtS9A=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fkatherine-burden-b6b58423%2F',
        text: [
            "Katherine is a skilled and knowledgeable business advisor with over 13 years experience working within the Green Technologies and Services sector.",
            'As co-founder of a low carbon consultancy, Katherine brings first-hand experience of running a small business coupled with specialist knowledge of the sector.',
            'With a passion for helping small businesses to thrive, Katherine has worked on numerous business support programmes and continues to use her sector insight and business acumen to advise clients on topics such as sales & marketing, exporting, business planning and market research.'
        ]
    },
    {
        id: 'VW',
        img: VW,
        name: 'Vicky Wilding',
        position: 'Senior Business Advisor',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQEtgVw0vsJWCwAAAYH33e8wbFFGrP5ULc7WLuLSvfeHUnmEdDFUTEuEIvvDr243el5liUJTcBjDhkNQgG9JmS_ghKOIci5gFB7yHsFBYK4K4ez6A8cdrOsPnyvg0yPKUFRY4Dc=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fvicky-wilding-23954827%2F',
        text: [
            'Vicky manages a team of advisors, delivering award-winning sector support for the Green Economy team. She has over 10 years experience supporting businesses across the low carbon and environmental goods and services(LCEGS) sector and has an esoteric understanding of barriers to growth, offering reactive advice about the challenges facing the green sector.',
            "With a combined Master's degree in Town and Country planning with Landscape Planning Management, Vicky understands that places need to be developed in a sustainable way that is inclusive of entire communities.",
            'Vicky is part of the Green Economy team because she wants to build client relationships and continue her involvement in a transformative sector that progresses social and environmental goals for future generations.'
        ]
    },
    {
        id: 'JM',
        img: JM,
        name: 'Jacqui Musson',
        position: 'Communications and Engagement Manager',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF5nuZIeU7zggAAAYH36HpoUt3IUakWQfA9UW6-b6d0iIEk7bOSGvbZcY4UMYX-Fk8Rr7KleSlZ3lryE1XPvTqWN6IH3f4dsW_rxcwlZ-OoRtPEnVoXBF-S4W3rheEAmaPmIr0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fjacquimusson%2F',
        text: [
            'Jacqui is a communications specialist with two decades experience in developing strategies that deliver. A member of the Chartered Institute of Public Relations, Jacqui has worked in third sector communications roles specialising in B2B campaigns that drive change within manufacturing, energy and business support.',
            'Jacqui leads communications and engagement for Green Economy, editing Green Intelligence to ensure our members have access to the latest news impacting their business, and positioning the sector on a national stage to advocate for our members, with the aim of increasing confidence in the supply chain and developing the sector to deliver the net zero ambition.'
        ]
    },
    {
        id: 'TC',
        img: TC,
        name: 'Thalia Czarkowska',
        position: 'Business Advisor',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGgc2LRz5ofNQAAAYH36664_4Ham1Dlj0osugdG-NK6BzJkpWLgcTac3OAk_jFezLG5MKsRP7Ip6uOaXmr11V40AjSDyz65gNSPe65CCHfmnqHJupcyM0U0PPG3j6xPxgC9IVI=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fthalia-czarkowska-506685174%2F',
        text: [
            'Thalia has been working with green technologies and services businesses since joining Green Economy in March 2017, after graduating from the University of Manchester with a BSc in Environmental Management.',
            'Her role as Business Advisor sees her working with clients to provide tailored business support as well as access to intelligence that will raise the profile of firms in the sector. Thalia is passionate about supporting businesses in the sector to achieve their growth potential, whilst helping them to access new opportunities that deliver the net zero ambition.',
            "Thalia also takes a lead role in managing the team's digital content and online services including Green Intelligence and Low Carbon Network."
        ]
    },
    {
        id: 'PS',
        img: PS,
        name: 'Priya Sirpal',
        position: 'Business Advisor',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFYNxfZR1M5bgAAAYH37zzgbu7kTME4zisb96jb3jmz721SwdFPA6adighzBnJm66eOHev8qjPGX6oDJZtnmTFDeeoq1C1DqqfN_bx5grVZhttVboo1pKDJCpDmLAjGTrtjkZg=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fpriya-sirpal-msc-b8b63b25%2F',
        text: [
            "Priya is responsible for on-boarding buyers and providing sales opportunities for our suppliers. She manages client relationships across the board, increasing awareness of low carbon products and services while assisting businesses in their net zero journey.",
            'Priya has over 10 years experience in recruitment, specialising in senior appointments across Construction, Manufacturing and Architecture. With an MSc in Occupational Psychology, Priya has a good insight into business dynamics and complicated relationship management. Her experience across multiple sectors has allowed her to witness the low-carbon evolution of businesses and naturally transition into the green sector as an avid relationship - builder.'
        ]
    },
    {
        id: 'ASHH',
        img: ASHH,
        name: 'Ashley Hulme',
        position: 'Business Advisor',
        linkedin: 'https://www.linkedin.com/in/ashleyhulme/',
        text: [
            "Ash is a business advisor with experience as the director of Ligero Design, who create and manufacture ultra-efficient LED lighting systems. He started Ligero Design to help organisations make sustainable choices, and he has an advanced understanding of the low-carbon technologies businesses can adopt to achieve net zero targets and sustainable growth.",
            "Ash has an in-depth knowledge of the supply chain process, working with staff at all levels to implement strategies related to green retrofitting, energy saving, and decarbonisation. He is committed to helping companies make the sustainable decisions necessary to reach net zero and keenly follows new innovations and technology across the sector.",
            "Aside from his experience in the green technologies and services sector, Ash is a School Governor who assists the Careers Lead in developing career pathways and connecting with local businesses to utilise their support. "
        ]
    },
    {
        id: 'TM',
        img: TM,
        name: 'Tim Mooney',
        position: 'Communications & Engagement Officer',
        linkedin: 'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQE0-yQYWDX6mgAAAYH4BtfYXQQvY8SFVSOoeLnzC25QCc46IKrCNLQFgZZl2e97qozF25SYE7Px83FzB-Eyk_DEcNMOHvDxEvcU5lJru7sNV4Hohs2zmgREF6AhF7Ih9WRlMCk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Ftim-mooney-98618a189%2F',
        text: [
            "Tim is responsible for communicating Green Economy's projects, partnerships and goals by developing content and engagement material. He is the one firing Green Intelligence content into your inboxes, on our website, and across social media feeds.",
            'His editorial work with environmental organisation Friends of the Earth inspired his decision to enter the world of net zero and sustainable business. Tim is interested in conservation, rewilding, decarbonisation and social development, with an academic background in Environmental and Developmental Economics.'
        ]
    },
    {
        id: 'LW',
        img: LW,
        name: 'Lola Wiseberg',
        position: 'Market Intelligence Officer',
        linkedin: 'https://www.linkedin.com/in/lola-wiseberg-19a839183/',
        text: [
            "Lola is a market intelligence officer, keeping the team up to date with the newest information from the green technology sector. She assists the team with research and analytics to ensure our clients have the most relevant knowledge for their individual needs.",
            'Lola graduated from the University of Manchester with an MSc in International Development, with a focus on policy. The environmental knowledge she learnt from this degree, in addition to a longstanding interest in sustainability, was what drove her into the world of green tech.'
        ]
    },
    {
        id: 'MS',
        img: MS,
        name: 'Mark Shepherd',
        position: 'Business Advisor',
        linkedin: '',
        text: [
            "Mark joined the Green Economy team as an advisor to Green Tech and Services businesses providing market intelligence and individual support to drive uptake within businesses. His previous role involved working with SMEs to measure their carbon emissions and develop a Net Zero plan.",
            'He brings experience in sales team leadership, business development and project management across a range of sectors including transport and logistics, international trade, engineering and advanced manufacturing, leadership coaching and peer group mentoring. Mark has also qualified as a domestic energy assessor and has experienced challenging conditions working on high speed transfer vessels during the construction phase of an offshore windfarm in the Irish Sea.',
            'Having a strong interest in circular design and innovation in locally generated renewable energy projects, Mark enjoys working with and supporting green tech and service businesses.'
        ]
    },
    {
        id: 'BP',
        img: BP,
        name: 'Ben Peace',
        position: 'Net Zero Principal',
        linkedin: '',
        text: [
            "As Net Zero Principal at Green Economy, Ben is helping industry to decarbonise and realise a green industrial revolution, driving collaboration and knowledge sharing that can unlock rapid transformation to make industry more competitive, resilient and sustainable.",
            'Ben works with individual businesses as well as targeted challenges, sectors, clusters and demonstrators, liaising closely with a broad range of stakeholders. Ben has a multi-disciplinary approach and broad experience which includes ten years in clean-tech (in a tech start-up and then a multi-national), then ten years working to support industry across sectors on programmes such as the Northwest Eco Innovation programme and then Innovate UK KTN where he was Sustainability Lead and Head of Manufacturing. As part of his role h helped inform funding across the UK on topics such as circular economy and industry 4.0. This was followed by a role as Sustainability Director at supply chain and network software business Valuechain where he helped build in sustainability, secure investment and commercialise across various software platforms.'
        ]
    }
]
