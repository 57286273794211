import { useState, useEffect } from "react";

import MailModal from "../../components/MailModal/EnquiryModal";

import styles from "./Buyers.module.css";
import marketplace from "../../assets/images/pageIcons/ge-marketplace.png";
import decarbon from "../../assets/images/pageIcons/DeCarbon06.png";
import sustain from "../../assets/images/pageIcons/Sustain04.png";
import deliver from "../../assets/images/pageIcons/Deliver11.png";

import showcase from '../../assets/images/misc/Showcase.jpg';
import newsales from '../../assets/images/misc/Newsales.jpg';
import network from '../../assets/images/misc/Network.jpg';
import netzero from '../../assets/images/misc/NetZero.jpg';
import cfac from '../../assets/images/misc/CfaC.jpg';

import Banner from "../../components/Banner/Banner";

import { setDescription, setTitle } from "../../utils";
import banner from "../../assets/images/banners/GE-Banner.png";
import { PageCard } from "../../components/PageCard/PageCard";

export const Buyers = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setTitle("Green Economy | Buyers: Renewable energy, Climate change");
        setDescription("Access expert support to reach local green suppliers for your tender, framework, project or procurement process.")
    }, []);

    return (
        <div>
            <MailModal show={show} handleClose={handleClose} title="Buyer help" email="info@greeneconomy.co.uk" template={2} />
            <Banner
                title="TRANSITION TO NET ZERO"
                src={banner}
                alt="Sales"
                text="EXPERT GUIDANCE AND ACCESS TO GREEN TECH"
                subText="SUPPLIERS ON YOUR JOURNEY TO NET ZERO"
                color='white'
                bgcolour="#02be7c"
            />
            <div className={styles.container}>
                <div className="mb-4">
                    <h2>Expert support on your journey to net zero </h2>
                    <p className="mb-2 FirstPara">
                        If you are looking to improve the environmental performance of your business, at Green Economy, we are here to help you to navigate the complex and fast-moving green technology and services sector to help you on that journey.
                        Our {/*<Link to="/marketplace" className="PLinkTo">Marketplace</Link>*/} Marketplace can help you to find eco friendly suppliers, installers and service providers with confidence, whilst our team of expert advisors can provide tailored support to find the best solutions available in the marketplace that are the right fit for your business.
                    </p>
                    <p>
                        If you are looking for a suitable contractor to partner with to deliver your services, by using our expertise and accessing the market intelligence we hold in our marketplace, we are here to make that supplier sourcing journey as smooth and effective as possible.
                    </p>

                    <a onClick={handleShow} className="PLinkTo">
                        Looking for a little more assistance? Get in touch
                    </a>
                </div>

                <PageCard
                    width="100%"
                    header="Marketplace - find your perfect project partner"
                    text="Search for local suppliers of green technologies and services to support your transition to net zero.
                        Search by location, specialism and accreditation to find a local expert and connect directly with the business to request a quotation or call back."
                    page="marketplace"
                    hasLogo={true}
                    logo={marketplace}
                    link="marketplace"
                    styling="marketplace"
                />
                <div className={styles.buyerCardContainer}>
                    <PageCard
                        width="100%"
                        header="Sales opportunities - promote your projects with Green Economy"
                        text="Increase your visibility with a searchable profile on the Marketplace, showcasing your products, services, specialisms and accreditations to buyers. "
                        hasLogo={true}
                        logo={showcase}
                        styling="imageFive"
                        btnText="Get in touch"
                        handleshow={handleShow}
                    />
                    <PageCard
                        width="100%"
                        header="Green Intelligence - the latest environmental news, views and analysis"
                        text="Get the latest industry news, insights and analysis impacting your business, or commission bespoke research including market research, consumer trends, sales forecasting, lead generation and competitor analysis."
                        page="news"
                        hasLogo={true}
                        logo={newsales}
                        link={process.env.REACT_APP_GI_URI}
                        styling="imageFive"
                        externalLink={true}
                    />
                    <PageCard
                        width="100%"
                        header="Green Economy events - demystify green technologies and network with suppliers"
                        text="Join webinars and networking events to understand the technologies at the heart of the net zero transition. 
                            Meet local suppliers and hear from experts to increase your understanding and confidence in the performance and longevity of low carbon products and services. "
                        page="news"
                        hasLogo={true}
                        logo={network}
                        link="events"
                        styling="imageFive"
                    />
                    <PageCard
                        width="100%"
                        header="Journey to Net Zero - prioritise your transition to carbon reduction"
                        text="Improve efficiency, achieve sustainability goals and access funding to deliver your transition to net zero, by working with a Sustainability and Net Zero advisor. Book a site visit and explore actions you can start today."
                        page="bgh"
                        hasLogo={true}
                        logo={netzero}
                        link="https://www.businessgrowthhub.com/services/sustainability-and-net-zero"
                        styling="imageFive"
                        externalLink={true}
                    />
                    <PageCard
                        width="100%"
                        header="Accreditations - demonstrate your environmental commitment "
                        text="Stand out from the crowd with accreditations that demonstrate your commitment to operating sustainably including PAS2060.  Our partners at the Centre for Assessment offer a range of assurances for your business."
                        page="cfa"
                        hasLogo={true}
                        logo={cfac}
                        link="https://www.centreforassessment.co.uk/environmental-certification/"
                        styling="imageFive"
                        externalLink={true}
                    />
                </div>
                <div className={styles.buyerCardContainer}>
                    <div className={styles.buyerCard}>
                        <PageCard
                            width="100%"
                            header="Decarbonising your workplace"
                            text="We can connect you with environmental services to measure and benchmark your carbon footprint and present recommendations for simple and more complex changes you can make to achieve carbon neutrality. 
                                We can recommend energy consulting or energy management services that will help you to understand the impact that renewable energy or self-generation can make for your business as energy security becomes of increasing concern. "
                            page="buyers"
                            hasLogo={true}
                            logo={decarbon}
                            styling="lightNoAction"
                        />
                    </div>
                    <div className={styles.buyerCard}>
                        <PageCard
                            width="100%"
                            header="Sustainable procurement"
                            text="Once you understand the potential for your net zero transition Green Economy can work with you to help implement that plan. Whether that is understanding the most impactful in terms of buy-back, 
                                or determining which technology is right for your specific needs. We can help businesses to influence their landlords or property managers of the long-term impact that solar panel installation and heat 
                                pump installation can have on the value of a property. We can also demystify technology and identify green finance or funding that will support capital expenditure decision making. "
                            page="none"
                            hasLogo={true}
                            logo={sustain}
                            styling="lightNoAction"
                        />
                    </div>
                    <div className={styles.buyerCard}>
                        <PageCard
                            width="100%"
                            header="Delivering sustainable projects"
                            text="There is an increased demand to decarbonise existing infrastructures across transport, housing, buildings and communications networks. 
                                This is achieved by retrofitting existing systems to improve their efficiency and utilising smart technologies to futureproof their sustainability in the long term. 
                                In addition new developments are under increased scrutiny to ensure that sustainable construction methods are utilised during the planning, development and operation stages. 
                                Green Economy can support these projects by understanding the capabilities of decarbonising major infrastructure projects and connecting you to subcontractors that can deliver for you."
                            page="public"
                            hasLogo={true}
                            logo={deliver}
                            styling="lightNoAction"
                        />
                    </div>
                </div>

            </div>
        </div>
    );
};
