import Modal from "react-bootstrap/Modal";
import Button from "../Button/Button";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { postEnquiryEmailData } from "../../utils";

const MailModal = (props) => {

    const [showButton, setShowbutton] = useState(true);
    const [showForm, setShowForm] = useState(true);
    const [emailSent, setEmailSent] = useState(true);

    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [disable, setDisable] = useState(false);
    const [template, setTemplate] = useState(1);

    const IntialEmailData = {
        name: "",
        company: "",
        email: "",
        emailsTo: [],
        subject: "",
        location: "",
        deadline: "",
        request: ""
    }
    const [emailData, setEmailData] = useState(IntialEmailData);

    const handleClose = () => {     
        props.handleClose();
        clearData();
    };

    const clearData = () => {
        setEmailData(IntialEmailData);
        setFields({});
        setErrors({});
        setDisable(false);
        const timer = setTimeout(() => setShowForm(true), 1000);
        return () => clearTimeout(timer);
    }

    useEffect(() => {
        
        const postEmail = async () => {
            if (emailData.email !== "" && emailData.subject !== "" && emailData.name !== "") {
                const data = await postEnquiryEmailData(emailData);
                setEmailSent(data);
                setShowbutton(false);
                setShowForm(false);
            }
        };
        postEmail();
    }, [emailData])

    useEffect(() => {
        //let emailsTo = props.multipleEmail ? props.multipleEmail : [props.email];
        setFields((values) => ({ ...values, 'emailsTo': props.multipleEmail ? props.multipleEmail : [props.email] }));
        setFields((values) => ({ ...values, 'Template': props.template }));
        if (props.template === 4) setFields((values) => ({ ...values, 'Title': props.title }));
        setTemplate(props.template)
        setShowbutton(props.show);
        setValidated(false);
        setDisable(false);
    }, [props])

    useEffect(() => {
        setShowForm(true);
    }, [])

    const handleValidation = () => {
        let formIsValid = true;
        let nameValid = true, emailValid = true, companyValid = true, subValid = true, locValid = true, deadValid = true, reqValid = true, acceptValid = true, contactValid = true;
        //Name
        nameValid = validateField("name", fields.name);
        //Company
        companyValid = validateField("company", fields.company);
        //Email
        emailValid = validateField("email", fields.email);
        //Subject
        subValid = validateField("subject", fields.subject);

        if (template === 1) {
            //location
            locValid = validateField("location", fields.location);
            //deadline
            deadValid = validateField("deadline", fields.deadline);
        } else
            contactValid = validateField("contact", fields.contact);

        //request
        reqValid = validateField("request", fields.request);
        //accept
        if (fields.accept === undefined) {
            setFields((values) => ({ ...values, 'accept': false }));
            acceptValid = false;
        } else acceptValid = fields.accept;

        formIsValid = (nameValid && emailValid && companyValid && subValid && locValid && deadValid && reqValid && acceptValid && contactValid);
        setValidated(formIsValid);
        return formIsValid;
    };

    const handleChange = (field, e) => {
        const value = e.target.value;

        if (field !== 'accept') {
            setErrors((errs) => ({ ...errs, [field]: '' }));
            setFields((values) => ({ ...values, [field]: value }));
            validateField(field, value); 
        }
        else {
            setFields((values) => ({ ...values, [field]: e.target.checked }));
            validateField(field, e.target.checked);
        }  
    }

    const validateField = (field, val) => {
        let isValid = true, errMes = '';

        switch (field) {
            case 'email':
                if (!val || val.length === 0) { errMes = 'Please enter an email'; isValid = false; }
                else {
                    isValid = val.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                    errMes = isValid ? '' : 'Please enter a valid email';
                }
                break;
            case 'name':
                if (!val || val.length === 0) { errMes = 'Please enter a name'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{2,100}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid Fullname';
                }
                break;
            case 'company':
                if (!val || val.length === 0) { errMes = 'Please enter a company'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{3,100}$)[a-zA-Z0-9]+(?:[-'\s][a-zA-Z0-9]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid Company';
                }
                break;
            case 'subject':
                if (!val || val.length === 0) { errMes = 'Please enter a subject'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{3,100}$)[a-zA-Z0-9\W]+(?:[-'\s][a-zA-Z0-9\W]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid subject';
                }
                break;
            case 'contact':
                if (!val || val.length === 0) { errMes = 'Please enter a contact number'; isValid = false; }
                else {
                    isValid = val.replace(/\D/g, '').match(/^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/);
                    errMes = isValid ? '' : ' Please enter valid contact number';
                }
                break;
            case 'location':
                if (!val || val.length === 0) { errMes = 'Please enter a location'; isValid = false; }
                else {
                    isValid = val.match(/^(?=.{2,100}$)[a-zA-Z0-9]+(?:[-'\s][a-zA-Z0-9]+)*$/);
                    errMes = isValid ? '' : ' Please enter valid location';
                }
                break;
            case 'deadline':
                if (!val || val.length === 0) {
                    errMes = 'Please enter a deadline date';
                    isValid = false;
                }
                break;
            case 'request':
                if (!val || val.length === 0) {
                    isValid = false;
                    errMes = 'Please enter the enquiry request';
                }
                break;
            case 'accept':
                isValid = val;
                break;
            default:
                break;
        }
        if (errMes !== '') setErrors((errs) => ({ ...errs, [field]: errMes }));
        
        return isValid;
    }

    const handleSubmit = (event) => {
        if (handleValidation()) {
            setDisable(true);
            setEmailData(fields);
        }      
    };

    const renderMessage = () => {
        let mes = {};
        if (!emailSent) {
            mes = <div>Email has not been sent. Please report this to <a href="mailto:info@greeneconomy.co.uk">info@greeneconomy.co.uk</a> or try again later</div>;
        } else {
            if (template !== 1) mes = <div>Thank you for your enquiry, a Green Economy advisor will be in touch shortly to discuss your requirements.</div>
            else mes = <div>Email has successfully been sent to the requested supplier(s), Thank you. You will now be contacted directly from this enquiry if the request is servicable by the company. </div>
        }
        return mes;
    }

    const renderForm = () => {
        const ppLink = <Link to='/privacy' target="_blank" rel="noopener noreferrer" style={{ color: 'var(--GEH-Green)', textDecoration: 'underline' }}>Privacy Policy</Link>
        const tLink = <Link to='/terms-and-conditions' target="_blank" rel="noopener noreferrer" style={{ color: 'var(--GEH-Green)', textDecoration: 'underline' }}>Terms of Use</Link>
        return (

            <Form noValidate validated={validated}>
                {props.multipleEmail && (
                    <Form.Group className="mb-1" controlId="enquiryForm.Comapnies">
                        <Form.Label>Enquiry to {props.multipleEmail.length} Companies</Form.Label>
                    </Form.Group>
                )}

                <Form.Group className="mb-1" controlId="enquiryForm.NameInput">
                    <Form.Label>Name</Form.Label>
                    <Form.Control placeholder="Name"
                        onChange={(e) => { handleChange("name", e) }}
                        value={fields.name || ''}
                        isValid={!errors.name && !!fields.name && fields.name !== ''}
                        isInvalid={!!errors.name && errors.name !== ''}
                        autoFocus
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors["name"]}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="enquiryForm.CompanyInput">
                    <Form.Label>Company</Form.Label>
                    <Form.Control placeholder="Company"
                        onChange={(e) => { handleChange("company", e) }}
                        value={fields["company"] || ''}
                        isValid={!errors.company && !!fields.company && fields.company !== ''}
                        isInvalid={!!errors.company && errors.company !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors["company"]}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1" controlId="enquiryForm.EmailInput">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="name@example.co.uk"
                        onChange={(e) => { handleChange("email", e) }} 
                        value={fields.email || ''}
                        isValid={!errors.email && !!fields.email && fields.email !== ''}
                        isInvalid={!!errors.email && errors.email !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors["email"]}
                    </Form.Control.Feedback>
                </Form.Group>

                {template !== 1 && (
                    <Form.Group className="mb-1" controlId="enquiryForm.ContactInput">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control
                            type="phone"
                            placeholder="07778 111 2222"
                            onChange={(e) => { handleChange("contact", e) }}
                            value={fields.contact || ''}
                            isValid={!errors.contact && !!fields.contact && fields.contact !== ''}
                            isInvalid={!!errors.contact && errors.contact !== ''}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors["contact"]}
                        </Form.Control.Feedback>
                    </Form.Group>
                )}
                
                <Form.Group className="mb-1" controlId="enquiryForm.SubjectInput">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control placeholder="Subject"
                        onChange={(e) => { handleChange("subject", e) }}
                        value={fields["subject"] || ''}
                        isValid={!errors.subject && !!fields.subject && fields.subject !== ''}
                        isInvalid={!!errors.subject && errors.subject !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors["subject"]}
                    </Form.Control.Feedback>
                </Form.Group>

                {template === 1 && (
                    <>
                        <Form.Group className="mb-1" controlId="enquiryForm.LocationInput">
                            <Form.Label>Location</Form.Label>
                            <Form.Control placeholder="Location"
                                onChange={(e) => {  handleChange("location", e) }}
                                value={fields["location"] || ''}
                                isValid={!errors.location && !!fields.location && fields.location !== ''}
                                isInvalid={!!errors.location && errors.location !== ''}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["location"]}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-1" controlId="enquiryForm.deadlineInput">
                            <Form.Label>Deadline</Form.Label>
                            <Form.Control type="date"
                                onChange={(e) => { handleChange("deadline", e) }}
                                value={fields["deadline"] || ''}
                                isValid={!errors.deadline && !!fields.deadline && fields.deadline !== ''}
                                isInvalid={!!errors.deadline && errors.deadline !== ''}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors["deadline"]}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </>
                )}
               
                <Form.Group className="mb-1" controlId="enquiryForm.RequesteInput">
                    <Form.Label>Request</Form.Label>
                    <Form.Control
                        placeholder="Request"
                        as="textarea"
                        rows={3}
                        onChange={(e) => { handleChange("request", e) }}
                        value={fields["request"] || ''}
                        isValid={!errors.request && !!fields.request && fields.request !== ''}
                        isInvalid={!!errors.request && errors.request !== ''}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors["request"]}
                    </Form.Control.Feedback>
                </Form.Group>
                {template === 1 &&
                    <Form.Group className={`blurb`}>
                        The information provided on this website is meant as a general guide only rather than advice or assurance. Green Economy (or any companies in The Growth Company group) do not guarantee the accuracy or completeness of this information and professional guidance should be sought on all aspects. Use of this website and any tools on this website is entirely at the risk of the user. Green Economy (or any companies in The Growth Company group) are not responsible for the content within any hyperlinked site to a supplier/partner/member website or other external resource. We may refer you to suppliers/partners/members or other information sources. A referral by us does not constitute an endorsement. Green Economy (or any companies in The Growth Company group) is not liable for any services, advice or information received from a referral supplier, partner, member or information source.
                    </Form.Group>
                }
                <Form.Group className="mb-1">
                    <Form.Check
                        required
                        label={<label>I have read and agree to the {template === 1 ? 'above Disclaimer' : tLink} and {ppLink}</label>}
                        type="checkbox"
                        onChange={(e) => { handleChange("accept", e) }}
                        isValid={fields.accept === true}
                        isInvalid={fields.accept === false}
                        feedback="You must agree before sending."
                        feedbackType="invalid"
                    />
                </Form.Group>
               
            </Form>

        );
    }

    return (
        <Modal show={props.show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!showForm ? renderMessage() : renderForm() }
            </Modal.Body>
            {showButton &&
                <Modal.Footer>
                    <Button text="Send" disabled={disable} onClick={handleSubmit} color="Green" />
                </Modal.Footer>
            }
        </Modal>
    );
};

export default MailModal;
