import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import style from "./Tree.module.css";

const ServiceTree = ({ data = [], handleCheck, savedSearchInputs }) => {
    return (
        <div className={style.dtree}>
            <ol className={`d-flex ${style.dtreecontainer} flex-column`}>
                {data.map((tree, idx) => (
                    <TreeNode key={idx} node={tree} handleCheck={handleCheck} savedSearchInputs={savedSearchInputs} />
                ))}
            </ol>
        </div>
    );
};

const TreeNode = ({ node, handleCheck, savedSearchInputs }) => {

    const [childVisible, setChildVisiblity] = useState(false);
    const hasChild = node.subSelectList ? true : false;
    
    return (
        <li key={node.id} className={`${style.dtreenode} border-0`}>
            <div className="d-flex" onClick={(e) => setChildVisiblity((v) => !v)}>
                {hasChild && (
                    <div
                        className={`d-inline ${style.dtreetoggler} ${childVisible ? style.active : ""
                            }`}
                    >
                        <FontAwesomeIcon icon="caret-right" className={style.icon} />
                    </div>
                )}
                <div className="col d-tree-head">
                    {hasChild ? node.value :
                        <Form.Check
                            key={node.value}
                            type="checkbox"
                            label={node.value}
                            id={node.value}
                            name={node.value}
                            defaultChecked={savedSearchInputs.service?.includes(node.value) ? true : ""}
                            onClick={(event) => handleCheck(event, "service")}
                            className={style.formcheck}
                         />
                    }
                </div>
            </div>

            {hasChild && childVisible && (
                <div className="d-tree-content">
                    <ol className={`d-flex ${style.dtreecontainer} flex-column`}>
                        <ServiceTree data={node.subSelectList} handleCheck={handleCheck} savedSearchInputs={savedSearchInputs} />
                    </ol>
                </div>
            )}
        </li>
    );
};

export default ServiceTree;