import React, {useEffect} from "react";

import { Link } from "react-router-dom";
import { setDescription, setTitle } from "../../utils"
import Banner from "../../components/Banner/Banner";

import bannerImg from "../../assets/images/banners/SalesHub.png";
import { PageCard } from "../../components/PageCard/PageCard";
import buyers from "../../assets/images/pageIcons/ge-buyers.png";
import suppliers from "../../assets/images/pageIcons/ge-suppliers.png";
import marketplace from "../../assets/images/pageIcons/ge-marketplace.png"
import aerialpanels from "../../assets/images/sales/aerial-solar-panels.jpg";
import solarstudy from "../../assets/images/sales/writing.jpg";
import cleanpanels from "../../assets/images/sales/clean-solar-panels.jpg";
import hydrogen from "../../assets/images/sales/home-hydrogen.jpg";
import evinfrastructure from "../../assets/images/sales/ev-infrastructure.jpg";
import insulation from "../../assets/images/sales/insulation.jpg";

import styles from "./Sales.module.css"

export const Sales = () => {

    useEffect(() => {
        setTitle("Green Economy | Sales Hub: Low carbon tender & green sales");
        setDescription("Green Economy’s Sales Hub provides tailored sales opportunities for green sector installers and suppliers.")
    }, []);

    return (
        <>
            <Banner
                title="Sales"
                src={bannerImg}
                alt="Sales"
                color='white'
                bgcolour="#ffc347"
            />
        <div className={styles.salesContainer}>
                  {/*<h2 className={styles.salesTitle}>Sales</h2>*/}

                  <div className={styles.salesIntroduction}>
                      <p>Green Economy is committed to working with buyers as well as suppliers to make procurement processes more accessible to businesses providing green technology and services.
                          The opportunities below are just a flavour of the projects, tenders and frameworks seeking green suppliers and installers.
                          Green Economy members access new opportunities first – sign up today to ensure you don’t miss out. 
                      </p>
                      <p className="text-center"><Link to="/membership" className="Purple btn btn-primary">
                          Access sales opportunities
                      </Link></p>
                  </div>             

                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={hydrogen} alt="Whole House Energy Retrofit Contract – Flintshire" title="Whole House Energy Retrofit Contract – Flintshire"/>
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Whole House Energy Retrofit Contract – Flintshire</h3>
                          <p>Flintshire Council is presenting a tender opportunity to design, supply, install, service and provide maintenance on a new and existing legacy whole house.</p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={cleanpanels} alt="Solar Panel Maintenance - Manchester" title="Solar Panel Maintenance - Manchester</" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Solar Panel Maintenance - Manchester</h3>
                          <p>Manchester based waste management firm seeks cleaning and maintenance service for their solar array which includes 327 panels. </p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={evinfrastructure} alt="Electric Vehicle Charging Infrastructure – Bury" title="Electric Vehicle Charging Infrastructure – Bury" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Electric Vehicle Charging Infrastructure – Bury</h3>
                          <p>Bury Council is looking to offer a list of locations to form the basis of a concessions contract for a supplier who is willing to install, operate, and maintain Electric Vehicle Charging Infrastructure (EVCI) at these locations. </p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={aerialpanels} alt="Solar PV Project – Manchester" title="Solar PV Project – Manchester" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Solar PV Project – Manchester</h3>
                          <p>A Manchester based business wishes to increase its green credentials and sustainability of their rented property by installing Solar PV. </p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={solarstudy} alt="Solar PV Feasibility Study - Salford" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Solar PV Feasibility Study - Salford</h3>
                          <p>A manufacturer based in Salford is interested in installing solar PV on their building, they would like to hear from local suppliers who could conduct a feasibility study and quote for the project.</p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={insulation} alt="Installation of roof and cavity wall insulation - national" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Installation of roof and cavity wall insulation - national</h3>
                          <p>A national heat pump installer is interested in developing a network insulation providers to service their growing customer base.</p>
                      </div>
                  </div>
                  <div className={styles.salesDetail}>
                      <div className={styles.salesImage}>
                          <img src={aerialpanels} alt="Solar PV Supply and Installation - Batley" title="Solar PV Supply and Installation - Batley" />
                      </div>
                      <div className={styles.salesInformation}>
                          <h3 className={styles.salesTitle}>Solar PV Supply and Installation - Batley</h3>
                          <p>AB Print Group wishes to select and appoint a suitable contractor for the design, supply and installation of the 110kW solar PV system</p>
                      </div>
                  </div>

                  <h2 className={styles.salesMore}>Access sales opportunities</h2>

                  <div className={styles.salesCardContainer}>                 
                      <div className={styles.salesCard}>
                          <PageCard
                              width="100%"
                              header="Supplier?"
                              text="Join Green Economy to raise your visibility with buyers and receive bespoke Sales Hub opportunities."
                              page="suppliers"
                              hasLogo={true}
                              logo={suppliers}
                              link="prices"
                              styling="standard"
                          />
                      </div>      
                      <div className={styles.salesCard}>
                          <PageCard
                              width="100%"
                              header="Buyer?"
                              text="Let us know about your new opportunities and let us find your perfect project partner"
                              page="buyers"
                              hasLogo={true}
                              logo={buyers}
                              link="buyers"
                              styling="standard"
                          />
                      </div>
                  </div>
                  <PageCard
                      width="100%"
                      header="Marketplace"
                      text="Use our directory of installers, equipment suppliers and solution providers to make an informed decision on your sustainability projects and support your local green economy"
                      page="marketplace"
                      hasLogo={true}
                      logo={marketplace}
                      link="marketplace"
                      styling="marketplace"
                  />
              </div>
        </>
  );
};