import bannerImg from "../../assets/images/banners/g-banner-sml.jpg";
import { Header } from "../../components/Header/Header";
import styles from "./News.module.css"

export const News = () => {
  return (
    <>
    <Header text="News" bgColour="#01402E" img={bannerImg}/>
    <div className={styles.container}>
      <h1>News</h1>
    </div>
    </>
  );
};