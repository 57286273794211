import React from "react";
import { Link } from "react-router-dom";

import styles from "./Footer.module.css";

import img from "../../assets/images/banners/bg-footer-doughnut.png";
import gcLogo from "../../assets/images/pageIcons/gc-logo-white.png";
import gmca from "../../assets/images/pageIcons/gmca2.png";
import ukgov from "../../assets/images/pageIcons/UKGov.png";
import ukspf from "../../assets/images/pageIcons/UKSPF.png";
import cyc from "../../assets/images/pageIcons/cyc.png";
import levelup from "../../assets/images/pageIcons/LevelUp.png";

export const Footer = () => {

    let year = new Date().getFullYear();

    return (
        <div className={styles.outer} style={{ backgroundImage: `url(${img})` }}>
            <div className={styles.container}>
                <div className={styles.column}>
                    <h2 className={styles.header}>Green Economy</h2>
                    <p>Lee House</p>
                    <p>90 Great Bridgewater Street</p>
                    <p>Manchester</p>
                    <p>M1 5JW</p>
                    <p>T: 0161 359 3050</p>
                    <p>
                        E: <a href="mailto:info@greeneconomy.co.uk" className={styles.link}>info@greeneconomy.co.uk</a>
                    </p>
                    <p className={styles.smallText} style={{ paddingTop: "16px", fontSize: "14px" }}>
                        Green Economy - part of The Growth Company
                    </p>

                    
                </div>
                <div className={`${styles.column}  ${styles.infoColumn}`}>
                    <h2 className={styles.header}>Useful Information</h2>
                    <div>
                        <span className="gcicon-right-open"></span>
                        <Link to="/privacy" className={styles.link}>
                            Privacy Policy
                        </Link>
                    </div>
                    <div>
                        <span className="gcicon-right-open"></span>
                        <Link to="/cookie-policy" className={styles.link}>
                            Cookie Policy
                        </Link>
                    </div>
                    <div>
                        <span className="gcicon-right-open"></span>
                        <Link to="/terms-and-conditions" className={styles.link}>
                            Terms and Conditions
                        </Link>
                    </div>
                    <div>
                        <span className="gcicon-right-open"></span>
                        <Link to="/accessibility-statement" className={styles.link}>
                            Accessibility Statement
                        </Link>
                    </div>
                    <div>
                        <span className="gcicon-right-open"></span>
                        <a
                            className={styles.link}
                            href="https://www.growthco.uk/about-us/policies/modern-slavery-and-human-trafficking-statement/"
                            target='_blank'
                            rel="noreferrer"
                        >
                            Modern Slavery Statement
                        </a>
                    </div>
                    <div className={styles.smallText} style={{ marginTop: "10px"}}>
                        <p>Registered in England number: 2443911</p>
                        <p>VAT Registration Number: 727102071</p>
                    </div>
                </div>
            </div>
            <div>
                <img className={styles.logo} src={gcLogo} alt="The Growth Company" />
                <img className={styles.logo} src={ukgov} alt="UK Gov" />
                <img className={styles.logo} src={gmca} alt="GMCA" />
                <img className={styles.logo} src={ukspf} alt="Yorkshire" />
                <img className={styles.logo} src={cyc} alt="City of York" />
                {/*<img className={styles.logo} src={levelup} alt="Level Up" />*/}
            </div>
            <div style={{ textAlign: "center", fontSize: "12px" }}>
                <p style={{ color: "lightgray" }}>© {year} The Growth Company </p>
            </div>
        </div>
    );
};
