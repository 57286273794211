import { useAuth } from "react-oidc-context";

export const AuthenticatedTemplate = (props) => {
    const auth = useAuth();

    return auth.isAuthenticated ? props.children : null;
};

export const UnauthenticatedTemplate = (props) => {
    const auth = useAuth();

    return !auth.isAuthenticated ? props.children : null;
};
