import React, { useEffect } from "react";

import styles from "./aboutUs.module.css";
import { PageCard } from "../../components/PageCard/PageCard";
import buyers from "../../assets/images/pageIcons/ge-buyers.png";
import suppliers from "../../assets/images/pageIcons/ge-suppliers.png";
import leaf from "../../assets/images/pageIcons/ge-leaf.png";
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import Banner from "../../components/Banner/Banner";
import { TeamView, AdvisoryView } from "../../components/Team/TeamView"
import { setDescription, setTitle } from "../../utils";

const AboutUs = () => {

    useEffect(() => {
        setTitle("Green Economy | About us: Amy House,Louise Marix Evans,Priya Sirpal");
        setDescription("Green Economy brings connects buyers with suppliers of green technologies, to deliver the transition to net zero.")
    }, []);

    return (
        <>
            <Banner
                title="ABOUT US"
                src={bannerImg}
                text="Meet the team and understand "
                subText="our vision for a net zero future"
                alt="About us"
                color='white'
                bgcolour="#02be7c"
            />

            <div className={styles.aboutUsContainer}>
                <div className={styles.blurbEventContainer}>
                    <div>
                        <p className={`FirstPara ${styles.blurbP}`}>
                            Green Economy is the evolution of decades experience of working with the businesses that will play a key role in delivering the transition to net zero. 
                            This transition represents a significant market opportunity for our economy with the whole of the supply chain set to benefit. 
                            Whilst some businesses are already well positioned to exploit these opportunities, 
                            others will require additional support to drive innovation and competitiveness, 
                            upskill their workforces and to benchmark their abilities against their peers. 
                        </p>
                        <p className={styles.blurbP}>
                            Simultaneously we need to address the market failures around uptake of low carbon technology by working with buyers to explore the potential of the sector.
                            Our ambition is to increase confidence and access to the sector, so that procuring green products and services isn’t just the right choice, it is also the easiest choice. 
                        </p>
                    </div>

                    <TeamView />

                    <AdvisoryView/>
                    
                    <h2>What is the green economy?</h2>
                    <div>
                        <p className={styles.blurbP}>
                            The green economy is defined as one that invests into businesses, infrastructure and assets that benefit the planet, reducing the risk of environmental harm and climate change.
                            At Green Economy our vision is to raise the profile of businesses that provide net zero solutions to increase investment in green technologies and services. 
                        </p>
                        <p className={styles.blurbP}>
                            Our Marketplace showcases the installers, tradespeople, providers, designers, suppliers and manufacturers of technologies and services including: 
                        </p>
                        <div className={styles.aboutUsCardContainer}>
                            <div className={styles.aboutUsCard}>
                                <ol>
                                    <li>Heat pumps</li>
                                    <li>Electric vehicles</li>
                                    <li>Energy saving / LED lighting</li>
                                    <li>Solar panels / PV</li>
                                    <li>Wind turbines</li>
                                    <li>Hydro energy</li>
                                    <li>Biomass / Biogas</li>
                                    <li>Micro CHP</li>
                                </ol>
                            </div>
                            <div className={styles.aboutUsCard}>
                                <ol>
                                    <li>Water harvesting</li>
                                    <li>Heat recovery</li>
                                    <li>Infrared heating</li>
                                    <li>Insulation</li>
                                    <li>Energy Management or consultancy</li>
                                    <li>Architecture / construction technology</li>
                                    <li>Environmental products and services</li>
                                </ol>  
                            </div>
                        </div>
                     </div>
                </div>

                <div className={styles.aboutUsCardContainer}>
                    <div className={styles.aboutUsCard}>
                        <PageCard
                            width="100%"
                            header="Transition to net zero"
                            text="Looking for a reputable installer or service provider for a green product or service?"
                            subText="Green Economy can help you find installers, suppliers and providers of net zero products and services."
                            page="buyers"
                            hasLogo={true}
                            logo={buyers}
                            link="buyers"
                            styling="light"
                        />
                    </div>
                    <div className={styles.aboutUsCard}>
                        <PageCard
                            width="100%"
                            header="Green business growth"
                            text="Do you install or supply low carbon technologies or provide green solutions?"
                            subText="Sell your products/services  and raise your profile through our online marketplace"
                            page="suppliers"
                            hasLogo={true}
                            logo={suppliers}
                            link="suppliers"
                            styling="light"
                        />
                    </div>
                    <div className={styles.aboutUsCard}>
                        <PageCard
                            width="100%"
                            header="Grow your green economy"
                            text="Does your local authority or city-region want to grow its green economy?"
                            subText="Green Economy helps businesses to transition to net zero whilst growing your local green technologies and services supply chain to reap the economic benefits."
                            page="public"
                            hasLogo={true}
                            logo={leaf}
                            link="public"
                            styling="light"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
