import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table'
import { setDescription, setTitle } from "../../utils";
import styles from "./prices.module.css";
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import { Results } from "./Results";
import { ResultsDisabled } from "./ResultsDisabled";
import RadioButton from "../../components/RadioGroup/RadioButton.js";
import RadioGroup from "../../components/RadioGroup/RadioGroup.js";
/*import "../../components/RadioGroup/RadioGroup.module.css";*/
import { getPlans } from "../../utils";
import Banner from "../../components/Banner/Banner";
import Button from "../../components/Button/Button.jsx";


const Prices = () => {
    const [inputs, setInputs] = useState({
        isGM: null,
        isSme: null,
        turnover: "",
        plans: null
    });

    const [showResults, setShowResults] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const ghLink = <a href='https://www.businessgrowthhub.com/who-we-are/fully-funded' target="_blank" rel="noopener noreferrer" style={{ color: 'var(--GEH-Purple)', textDecoration: 'underline' }}>Fully-Funded support | GC Business Growth Hub</a>

    useEffect(() => {
        setTitle("Green Economy | Membership: Join Green Economy");
        setDescription("Working with green tech firms to raise your profile, provide access to new supply chains and develop your business network.")
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {
            await getPlans().then(pr => { setInputs({ "plans": pr }); });
        };
        fetchPlans();
    }, []);

    useEffect(() => {
        if (inputs.isGM == null || inputs.isSme == null || inputs.turnover == null || inputs.turnover == "") {
            setShowButton(false);
        }
        else {
            setShowButton(true);
        }
    }, [inputs]);

    return (
         
        <>
            <Banner
                title="MEMBERSHIP"
                text="CREATE YOUR MARKETPLACE PROFILE AND GAIN"
                subText="ACCESS TO A HOST OF BUSINESS GROWTH BENEFITS"
                src={bannerImg}
                alt="Membership"
                color='white'
                bgcolour="#02be7c"
            />
            <div className={styles.pricesContainer}>

                <h2 className={styles.blurbTitle}>Join the Green Economy</h2>

                <p className={styles.blurbP}>
                    Do you install, supply, design, manufacture or distribute green technology and services? Green Economy is bringing together the installers, tradespeople and professionals we need to get the UK on the path to a sustainable, net zero economy into one single network.
                </p>
                <p>
                    Membership of Green Economy provides a host of opportunities to drive business growth including these benefits: 
                </p>
                <ul>
                    <li>Access to exclusive sales and tender opportunities</li>
                    <li>A searchable profile on the Marketplace</li>
                    <li>Networking and events, including invitations to Meet the Buyer sessions</li>
                    <li>Tailored business support and advice</li>
                </ul>
                <p>
                    Answer the questions below and calculate a membership price.
                </p>
                <div className={styles.blurbEventContainer}>
                    <div className={styles.blurbContainer}>
                        <div>
                            <p className={styles.pricesParagraph}>Is your business located in Greater Manchester?</p>
                            <div>
                                <RadioGroup>
                                    <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "isGM": true })); setShowResults(false); }} label="Yes" value="yes" />
                                    <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "isGM": false })); setShowResults(false); }} label="No" value="no" />
                                </RadioGroup>
                            </div>
                            <p className={styles.pricesParagraph}>Is your business an SME?</p>
                            <div>
                                <RadioGroup>
                                    <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "isSme": true })); setShowResults(false); }} label="Yes" value="yes" />
                                    <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "isSme": false })); setShowResults(false); }} label="No" value="no" />
                                </RadioGroup>
                            </div>
                            <p className={styles.pricesParagraph}>Select your business turnover</p>
                            <div>
                                <RadioGroup>
                                    <div>
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T0" })); setShowResults(false); }} label="£0-100k" value="t0" />
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T100" })); setShowResults(false); }} label="£100k-500k" value="t100" />
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T500" })); setShowResults(false); }} label="£500k-2m" value="t500" />
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T2000" })); setShowResults(false); }} label="£2m-10m" value="t2000" />
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T10000" })); setShowResults(false); }} label="£10m-25m" value="t10000" />
                                        <RadioButton onClick={function (e) { setInputs((values) => ({ ...values, "turnover": "T25000" })); setShowResults(false); }} label="&gt; £25m" value="t25000" />
                                    </div>
                                </RadioGroup>
                            </div>
                        </div>

                        {showButton && process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "true" &&
                            <div className={styles.result}>
                                <Button onClick={function (e) { setShowResults(true); }} text="Calculate Price" color="Green" />
                            </div>
                        }

                        {process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "false" &&
                            <div className={styles.result}>
                                <ResultsDisabled {...inputs} />
                            </div>
                        }

                    </div>

                    <div className={styles.blurbContainer}>
                        {showResults && process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "true" &&
                            <Results {...inputs} />
                        }

                        {process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "false" &&
                            <div>
                                <p className={styles.pricesParagraph}>
                                    Membership is based on company turnover and our annual fees are below.
                                </p>
                                <p>
                                    *Eligible businesses operating in Greater Manchester can access this service for free through the GC Business Growth Hub. {ghLink}
                                </p>
                                <br />
                                <Table bordered responsive width='100%' id="pricesTable" size="sm">
                                    <thead>
                                        <tr>
                                            <th>Annual turnover</th>
                                            <th>Annual fee +VAT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>£0-£100,000</td>
                                            <td>£240</td>
                                        </tr>
                                        <tr>
                                            <td>&gt;£100,000-£500,000</td>
                                            <td>£500</td>
                                        </tr>
                                        <tr>
                                            <td>&gt;£500,000 - £2m</td>
                                            <td>£750</td>
                                        </tr>
                                        <tr>
                                            <td>&gt;£2m-£10m</td>
                                            <td>£1000</td>
                                        </tr>
                                        <tr>
                                            <td>&gt;£10m-£25m</td>
                                            <td>£1500</td>
                                        </tr>
                                        <tr>
                                            <td>&gt;£25m</td>
                                            <td>£5000</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Prices;
