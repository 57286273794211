import styles from "./Error.module.css";
import "./error.css";

export const Error = ({ error, id, page }) => {
    return (
        <div className={page === "marketplace-page" ? page : styles.container}>
            <div>
                <h1 data-testid={id}>{error.message}</h1>
                <p onClick={() => window.location.reload()} className={styles.link}>
                    Click here to refresh
                </p>
            </div>
        </div>
    );
};
