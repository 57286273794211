import { useEffect, useState } from "react";

import { Link, Outlet } from "react-router-dom";

import styles from "./Sidebar.module.css";

import { handleTabStateBasedOnURL, handleTabStateManagement, setActiveTab } from "../../utils";

export const Sidebar = () => {
    const [tabStates, setTabStates] = useState({
        isOverviewActive: false,
        isOrdersActive: false,
        isProfileActive: false,
        isYourAccActive: false,
    });

    useEffect(() => {
        const currentTab = handleTabStateBasedOnURL();

        setActiveTab(currentTab);
    });

    const handleActiveClass = (e) => {
        const tabName = e.target.name;
        const tab = e.target;
        const parent = tab.parentElement;

        handleTabStateManagement(tabName, parent, tabStates, setTabStates);
    };

    return (
        <>
            <div className={styles.container}>
                <nav>
                    <ul className={styles.sidebarTable}>
                        <li
                            onClick={handleActiveClass}
                            name="overview"
                            className={`navTab ${tabStates.isOverviewActive ? "current" : null}`}
                        >
                            <Link to="overview" name="overview" className={styles.link}>
                                <span className="gcicon-eye"></span>Overview
                            </Link>
                        </li>
                        <li
                            onClick={handleActiveClass}
                            name="profile"
                            className={`navTab ${tabStates.isProfileActive ? "current" : null}`}
                        >
                            <Link to="profile" name="profile" href="hello" className={styles.link}>
                                <span className="gcicon-home"></span>Profile
                            </Link>
                        </li>
                        <li
                            onClick={handleActiveClass}
                            name="account"
                            className={`navTab ${tabStates.isYourAccActive ? "current" : null}`}
                        >
                            <Link to="account" name="account" className={styles.link}>
                                <span className="gcicon-user-1"></span>Your Account
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <Outlet />
        </>
    );
};
