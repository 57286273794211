import { useAuth } from "react-oidc-context";

const LoginRedirect = () => {
    const auth = useAuth();
    auth.signinRedirect();

    return (
        null
    );
};

export default LoginRedirect;
