import Spinner from "react-bootstrap/Spinner";

import "./loader.css";

export const Loader = ({ page }) => {
    return (
        <div data-testid="loader" className={`${page}`}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};
