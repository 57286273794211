import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import styles from "./Overview.module.css";
import { SupplierHeader } from "../../components/SupplierHeader/SupplierHeader";


const insightData = {
    pageViews: 50,
    bookmarked: 11,
};

const salesOpportunities = [
    {
        name: "John Smith",
        companyName: "Buyer Company Name or Title",
        enquiry: "Who can I contact about your products?",
    },
    {
        name: "Hannah Jones",
        companyName: "Buyer Company Name or Title",
        enquiry: "Who can I contact about your products?",
    },
    {
        name: "Emma Lowe",
        companyName: "Buyer Company Name or Title",
        enquiry: "Who can I contact about your products?",
    },
];

const yourEvents = [
    {
        date: "22nd November",
        title: "Green Texh Conference",
    },
    {
        date: "5th December",
        title: "Business Workshop Manager",
    },
    {
        date: "5th December",
        title: "Business Workshop Manager",
    },
];

export const Overview = ({ supplier }) => {
    return (
        <div className={styles.mainContent}>
            <SupplierHeader supplier={supplier} />
            <Card className={styles.dataCard}>
                <Card.Title className={styles.title}>
                    <p>Key insights for this week</p>
                </Card.Title>
                <Card.Body className={styles.body}>
                    <Card.Text className={styles.text}>
                        Page views:
                        <span>{insightData.pageViews}</span>
                    </Card.Text>
                    <Card.Text className={styles.text}>
                        Bookmarked by visitors:
                        <span>{insightData.bookmarked}</span>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className={styles.eventTable}>
                <Card.Title className={styles.title}>
                    <p style={{ fontWeight: "bold", textAlign: "left" }}>Events you're attending</p>
                </Card.Title>
                <Card.Body className={styles.body}>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {yourEvents.map((event, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            style={
                                                i === salesOpportunities.length - 1
                                                    ? { fontWeight: "bold", padding: "5px 0px 0" }
                                                    : { fontWeight: "bold" }
                                            }
                                        >
                                            {event.date}
                                        </td>
                                        <td>{event.title}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
            <Card className={styles.salesCard}>
                <Card.Title className={styles.title}>
                    <p>
                        Sales opportunities <span className={styles.salesSpan}>{salesOpportunities.length}</span>
                    </p>
                    <Link to="/sales" className={styles.link}>
                        Go to Sales Hub <span className="gcicon-right"></span>
                    </Link>
                </Card.Title>
                <Card.Body>
                    <table>
                        <tbody>
                            {salesOpportunities.map((opportunity, i) => {
                                return (
                                    <tr
                                        key={i}
                                        style={i === salesOpportunities.length - 1 ? { color: "lightgrey" } : null}
                                    >
                                        <td>{opportunity.name}</td>
                                        <td>{opportunity.companyName}</td>
                                        <td>{opportunity.enquiry}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Card.Body>
            </Card>
            <Card className={styles.profileContainer}>
                <Card.Title style={{ display: "flex", justifyContent: "space-between", paddingBottom: "16px" }}>
                    <p style={{ fontWeight: "bold" }}>Your profile</p>
                    <Link to="/dashboard/profile" name="profile" className={styles.link}>
                        Edit profile <span className="gcicon-right"></span>
                    </Link>
                </Card.Title>
                <div className={styles.innerContainer}>
                    <section>
                        <span className={`gcicon-notepad ${styles.profileIcon}`}></span>
                        <p>Details</p>
                    </section>
                    <section>
                        <span className={`gcicon-briefcase-1 ${styles.profileIcon}`}></span>
                        <p>Section 2</p>
                    </section>
                    <section>
                        <span className={`gcicon-cogs ${styles.profileIcon}`}></span>
                        <p>Section 3</p>
                    </section>
                    <section>
                        <span className={`gcicon-bar-chart ${styles.profileIcon}`}></span>
                        <p>Section 4</p>
                    </section>
                    <section>
                        <span className={`gcicon-eye ${styles.profileIcon}`}></span>
                        <p>Section 5</p>
                    </section>
                </div>
            </Card>
        </div>
    );
};
