import { useState, useEffect } from "react";
import Button from "../../components/Button/Button.jsx";
import MailModal from "../../components/MailModal/EnquiryModal";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../../components/Oidc/Authentication";
import styles from "./prices.module.css";

export const Results = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [plan, setPlan] = useState(null);
    const [features, setfeatures] = useState([""]);
    let btnText = "Submit an Enquiry"; 

    const handleClick = () => {
        handleShow();
    };

    useEffect(() => {

        const getPlanCode = () => {
            const defaultCode = "L1Y1T0F0";
            let shortCode = defaultCode;

            if (props.isGM == null && props.isSme == null && (props.turnover == null || props.turnover.length === 0)) {
                return defaultCode;
            }
            else {
                shortCode = "L2Y1";
            }

            if (props.turnover?.length > 0) {
                shortCode = shortCode + props.turnover
            }
            else {
                shortCode = shortCode + "T0"
            }

            if (props.isGM === true && props.isSme === true && props.turnover === "T0") {
                shortCode = shortCode + "F1"
            }
            else {
                shortCode = shortCode + "F0"
            }

            return shortCode;
        }

        const getPlan = (shortCode) => {
            let plan = props.plans.find(pl => pl.plan_code_short === shortCode);
            return plan;
        }

        if (props.plans == null) {
            return null;
        }

        let shortCode = getPlanCode();
        let pl = getPlan(shortCode);
        setPlan(pl);
        setfeatures(pl.store_markup_description.split("|"));
    }, [props])

   return (
       <>
           <div>
               <p className={styles.pricesParagraph}>
                   Subscription name: {plan?.name}
               </p>
               <p className={styles.pricesParagraph}>
                   Description: {plan?.description}
               </p>
               <p className={styles.pricesParagraph}>
                   Features: 
               </p>
               <ul>
                   {features.map((item, index) => {
                       return <li key={index}>{item}</li>;
                   })}
               </ul>
               <p className={styles.pricesParagraph}>
                   Price: &pound;{plan?.recurring_price}.00
               </p>
            </div>
           <UnauthenticatedTemplate>
               <a href={plan?.url} target="_blank" rel="noreferrer">
                   <Button color="Green" text="Buy Subscription">Click</Button>
               </a>
               <MailModal show={show} handleClose={handleClose} title="Membership Enquiry" email="info@greeneconomy.co.uk" template={3} />
               <Button onClick={handleClick}  text={btnText} color="LightGreen" />
           </UnauthenticatedTemplate>
           <AuthenticatedTemplate>
               <p className={styles.pricesParagraphPurple}>You are already registered and can use the site.</p>
           </AuthenticatedTemplate>
        </>
    )
}