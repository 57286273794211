import styles from './SupplierHeader.module.css'


export const SupplierHeader = ({ supplier }) => {

    return (
        <div className={styles.header}>
            <h1 className={styles.title}>{supplier.account_Name}</h1>
        </div>
    )
}