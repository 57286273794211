import Nav from 'react-bootstrap/Nav';
import "./toggle.css";

export const Toggle = ({setToggle }) => {
    const handleSelect = (eventKey) => setToggle(eventKey);
    return (
        <>
            <div className="EventsTabs">
                <Nav justify variant="underline" defaultActiveKey="Events" onSelect={handleSelect }>
                    <Nav.Item>
                        <Nav.Link eventKey="Events">Events</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="Training">Training</Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>

        </>
    );
};

export default Toggle;
