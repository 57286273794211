import { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Supplier.module.css";
import Banner from "../../components/Banner/Banner";
import { setDescription, setTitle } from "../../utils";
import { PageCard } from "../../components/PageCard/PageCard";
import banner from "../../assets/images/banners/GE-Banner.png";
import aroundtable from '../../assets/images/misc/aroundthetable.jpg';
import prema from '../../assets/images/misc/premaenergy.jpg';
import showcase from '../../assets/images/misc/Showcase.jpg';
import newsales from '../../assets/images/misc/Newsales.jpg';
import grow from '../../assets/images/misc/Grow.jpg';
import network from '../../assets/images/misc/Network.jpg';
import electric from "../../assets/images/pageIcons/ge-electric.png";

const Supplier = () => {

    useEffect(() => {
        setTitle("Green Economy | Suppliers: Energy heat & solar installers");
        setDescription("Providing tailored support for insulation installer, heat pump installers, solar installers, LED lighting installers and energy managers.")
    }, []);

    return (
        <>
            <Banner
                title="GREEN BUSINESS GROWTH"
                src={banner}
                alt="Supplier"
                text="RAISE YOUR PROFILE, WIN NEW BUSINESS"
                subText="AND SCALE AND GROW YOUR FIRM"
                color='white'
                bgcolour="#02be7c"
            />
            <div className={styles.supplierContainer}>
                <h2>Do you supply or install low carbon technologies or provide green solutions?</h2>
                <p className="mb-3 FirstPara">
                    Green Economy is bringing together the installers, tradespeople and professionals we need to get the UK on the path to a sustainable, net zero economy into one single network.
                    From solar panels to heat pumps, green building technologies and everything in between, we provide suppliers with support to develop their business and win more work.
                </p>
                <p className="mb-3">
                    Our team of sector <Link to="/about-us" className="PLinkTo">specialists</Link> have vast experience working with businesses to understand their competitive advantage and seize opportunities for growth.
                    With expertise in business planning, sales forecasting, competitor reviews, service level proposition support and funding and investment streams.Crucially, 
                    the team understand the specific challenges the sector faces with the low and slow uptake up of green technologies within businesses that either have limited appetite
                    to transition to net zero or struggle to grasp the complex and fast-moving technologies required. At Green Economy we're helping to demystify these technologies and provide assurance
                    to business that they're investing wisely, whilst introducing suppliers that can meet their specific requirements. 
                </p>
                <h4>Green Economy provides services to help businesses in the green technologies and services sector grow</h4> 
                <div className={styles.supplierCardContainer}>
                    <PageCard
                        width="100%"
                        header="Showcase your products and services"
                        text="Increase your visibility with a searchable profile on the Marketplace, showcasing your products, services, specialisms and accreditations to buyers. "
                        subText={["A free, searchable profile on the Marketplace",
                            "Direct enquiries from live buyers",
                            "Green Economy verified badge for members"]}
                        hasLogo={true}
                        logo={showcase}
                        styling="imageFour"
                        listsubtext={true}
                    />
                    <PageCard
                        width="100%"
                        header="Access exclusive sales opportunities"
                        text="In addition to direct enquiries from buyers, Green Economy members will receive bespoke invitations to new business development opportunities."
                        subText={["Invitations to submit expressions of interest for commercial projects and contracts",
                            "A weekly round-up of tenders for net zero projects",
                            "Invitations to meet the buyer and business development events and exhibitions"]}
                        hasLogo={true}
                        logo={newsales}
                        styling="imageFour"
                        listsubtext={true}
                    />
                    <PageCard
                        width="100%"
                        header="Network, collaborate and learn"
                        text="Ensure your business is ready to compete as the UK transitions to net zero. Upskill your workforce, collaborate with peers and embed good practice techniques with a range of events, networks and training."
                        subText={["Green Leaders masterclasses designed to help you seize new market opportunities and you're your business",
                            "Build business resilience and growth opportunities by collaborating with green leaders at networking events",
                            "Stay on top of industry trends and hear from our members with lunchtime learning."]}
                        hasLogo={true}
                        logo={network}
                        styling="imageFour"
                        listsubtext={true}
                    />
                    <PageCard
                        width="100%"
                        header="Grow your business"
                        text="For green technology and services businesses in Greater Manchester, we provide an enhanced package of support designed to help you grow your business. Services include:"
                        subText={["Our bespoke green technologies business health check, with a personalised action plan for growth.", "121 tailored business support from a green technologies and services sector specialist.", "Access to market intelligence and competitor analysis to help you scale and grow your business."]}
                        hasLogo={true}
                        logo={grow}
                        styling="imageFour"
                        listsubtext={true}
                    />
                </div>
                <div className="mb-2 mt-2">
                    <PageCard
                        width="100%"
                        header="Join Green Economy"
                        text="Join Green Economy today to increase your company profile and win new business."
                        subText={["Membership of Green Economy is offered at two levels - Spark and Build. Visit our Membership page to start creating your free profile and access additional services.",
                        "If you're a green technologies and services sector SME based in Greater Manchester your build membership is fully-funded by GC Business Growth Hub. Click here to access your free benefits today."]}
                        hasLogo={true}
                        logo={electric}
                        link="membership"
                        styling="standard"
                        listsubtext={true}
                    />
                </div>
                <h2 className="mt-1">Green Economy member wins</h2>
                <div className={styles.supplierCardContainer}>
                    <PageCard
                        width="100%"
                        header="Boarding the Solar-Coaster - Pitching Green Technology to Prospective Buyers"
                        text="Ellard are in the business of automation and, as part of their Scope One plan to be net zero by 2025, met with Green Economy's solar installation members to achieve their decarbonisation goals."
                        page="ellard"
                        hasLogo={true}
                        logo={aroundtable}
                        link={process.env.REACT_APP_GI_URI + "/case-studies/boarding-the-solar-coaster-pitching-green-technology-to-prospective-buyers/"}
                        styling="image"
                        btnText="Find out more"
                        externalLink={true}
                    />
                    <PageCard
                        width="100%"
                        header="Shifting to low carbon products"
                        text="Green Economy helped Prema Energy pivot towards low carbon products and services saving 6,700 tonnes of CO2e emissions for their customers."
                        page="prema"
                        hasLogo={true}
                        logo={prema}
                        link={process.env.REACT_APP_GI_URI + "/case-studies/shifting-to-low-carbon-products/"}
                        styling="image"
                        btnText="Find out more"
                        externalLink={true}
                    />
                </div>            
            </div>
        </>
    );
};

export default Supplier;
