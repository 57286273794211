import React, { useState, useEffect } from "react";

import Social from "../../components/Social/social";
import MailModal from "../../components/MailModal/EnquiryModal";
import { PageCard } from "../../components/PageCard/PageCard";

import { setDescription, setTitle } from "../../utils";

import prema from '../../assets/images/misc/premaenergy.jpg';
import gm from '../../assets/images/misc/GM.jpg';
import geanimeimg from "../../assets/video/geanime.png"
import Banner from "../../components/Banner/Banner";
import bannerImg from "../../assets/images/banners/GE-Banner.png";

import styles from "./research.module.css";

const Research = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setTitle("Green Economy | Research and Intelligence: Research, Intelligence");
        setDescription("Bespoke insights into the green tech sector and the net zero transition, Access quality intelligence about the net zero opportunity for your business or region")
    }, []);

    return (
        <>
            <Banner
                title="RESEARCH AND INTELLIGENCE"
                src={bannerImg}
                alt="Research"
                text="BESPOKE INSIGHTS INTO THE GREEN TECH SECTOR"
                subText="AND THE NET ZERO TRANSITION"
                color="white"
                bgcolour="#02be7c"
            />

            <div className={styles.researchContainer}>
                {/*<h2>Research and Intelligence</h2>*/}
                <div className={styles.blurbContainer}>
                    <div className={styles.textContainer }>
                        {/*<p className="FirstPara mb-2">Bespoke insights into the green tech sector and the net zero transition</p>*/}
                        <h2 className="mb-2">Access quality intelligence about the net zero opportunity for your business or region </h2>
                        <p className="mb-2 FirstPara">At Green Economy we understand that data is central to good decision making, whether that be at a micro or macro level.
                            This is why research and intelligence have been central in our support for the green technologies and services sector, helping businesses and policy makers understand the scope and size of the sector,
                            in order to seize the opportunities at hand. </p>
                        <ul>
                            <li>
                                For businesses we provide bespoke intelligence including market research, consumer trends, sales forecasting, lead generation and competitor analysis, helping businesses to plan strategically, innovate and enter new markets.
                                This insight enables us to work with non-green businesses, helping them to diversify into the sector by expanding their range of products and services.
                                Our focus in on developing competitive businesses, whilst embedding the principles of the circular economy. 
                            </li>
                            <li>
                                For policymakers we have provided a complete sector mapping study to understand the local opportunities and barriers to adoption and using this insight to scale the local sector.
                                We've also provided deep dive analysis into specific markets or industries including housing and hydrogen, to understand the niche challenges at play.
                            </li>
                        </ul>
                        {/*<p className="mb-2">For businesses we provide bespoke intelligence including market research, consumer trends, sales forecasting, lead generation and competitor analysis, helping businesses to plan strategically, innovate and enter new markets.*/}
                        {/*    This insight enables us to work with non-green businesses, helping them to diversify into the sector by expanding their range of products and services. */}
                        {/*    Our focus in on developing competitive businesses, whilst embedding the principles of the circular economy. */}
                        {/*</p>*/}
                    </div>
                    <div className={styles.video }>
                        <video id="geanime" poster={geanimeimg} controls>
                            <source src={process.env.PUBLIC_URL + '/GEAnime.mp4'} type="video/mp4"/>
                            Your browser does not support the video tag.
                         </video>
                    </div>
                </div>
                {/*<p className="mb-2">For policymakers we have provided a complete sector mapping study to understand the local opportunities and barriers to adoption and using this insight to scale the local sector.*/}
                {/*    We've also provided deep dive analysis into specific markets or industries including housing and hydrogen, to understand the niche challenges at play.*/}
                {/*</p>*/}
                <p className="mb-2">
                    Take a look at our recent report for Greater Manchester. <a href={process.env.REACT_APP_GI_URI + "/reports/greater-manchester-s-green-economy/"} target="_blank" rel="noopener noreferrer" className="PLinkTo">Greater Manchester's green economy - sector mapping study</a>
                </p>
                <p className="mb-2">We're passionate about increasing understanding of the businesses at the heart of the transition to net zero. Speak to us to see how we can support your strategic planning. </p>
                <MailModal show={show} handleClose={handleClose} title="Enquiry" email="info@greeneconomy.co.uk" template={2} />
                <p className="mb-2">To speak to us, please<a onClick={handleShow} className="PLinkTo"> submit an enquiry</a></p>
                <p className="mb-2">
                    Click here to read our published reports <a href={process.env.REACT_APP_GI_URI + "/reports"} target="_blank" rel="noopener noreferrer" className="PLinkTo">Green Intelligence | environmental reports</a>
                </p>
                <PageCard
                    width="100%"
                    header="Green Intelligence - industry analysis, news and views"
                    text="Green Economy publishes the latest environmental business news, insights and analysis impacting your business on Green Intelligence - our publishing home for over a decade. 
                        You can subscribe to receive a monthly roundup of our latest content or get regular updates by following our social media channels."
                    page="GI"
                    hasLogo={false}
                    styling="link"
                    link={process.env.REACT_APP_GI_URI}
                    externalLink={true}
                    listsubtext={true}
                />
                <div className="mb-2 mt-3">
                    <Social />
                </div>
                <h3 className="mt-1">Testimonials</h3>
                <div className={styles.researchCardContainer}>
                    <PageCard
                        width="100%"
                        header="<i>&#8220;Green Economy has provided us with outstanding market intelligence support. They helped us to identify commercial partners that can assist our clients with meeting their net zero objectives. 
                            We look forward to growing our revenue and headcount as we integrate these new products into our service offering.&#8221;</i>"
                        text="<b>Will Goldsmith</b>, Commercial Manager, Prema Energy"
                        page="prema"
                        hasLogo={true}
                        logo={prema}
                        styling="image"
                    />
                    <PageCard
                        width="100%"
                        header="<i>&#8220;Green Economy has played a key role in helping Greater Manchester grow the UK's second largest green technologies and services sector, behind only London and the South East. 
                                The team is uniquely placed to support these businesses, understanding the nuances of this vast and evolving sector, whilst also having specialist understanding of the challenges at play 
                                at a local level to develop support programmes that turn policy into a reality.&#8221;</i"
                        text="<b>Mark Atherton</b>, Director of Environment, Greater Manchester Combined Authority "
                        page="gm"
                        hasLogo={true}
                        logo={gm}
                        styling="image"
                    />
                </div>        
            </div>
        </>
    )
}

export default Research;