
import Carousel from 'react-bootstrap/Carousel';

import city1 from '../../assets/images/Carousel/city1.jpg';
import car1 from '../../assets/images/Carousel/car-1.jpg';
import eco from '../../assets/images/Carousel/ecology-c.jpg';
import bulb from '../../assets/images/Carousel/light-bulb.jpg';
import sunset from '../../assets/images/Carousel/sunset-c.jpg';

function Rendercarousel() {
    return (
        <Carousel fade indicators={true} controls={false}>
            <Carousel.Item interval={5000} style={{ backgroundColor: '#19b997', height:'200px' }}>
                <div className="d-block w-100" >
                <img
                        className="w-100 d-inline-block float-end"
                        src={city1 }
                    alt="First slide" height="200"
                />
                    <Carousel.Caption style={{ color: 'black' }}>
                    <h3>First slide</h3>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </div>
            </Carousel.Item>
            <Carousel.Item interval={5000} style={{ backgroundColor: '#19b997', height: '200px' }}>
                <img
                    className="d-inline-block float-end"
                    src={car1}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h3>Second slide</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{ backgroundColor: '#19b997', height: '200px' }}>
                <img
                    className="w-100 d-inline-block float-end"
                    src={sunset}
                    alt="Third slide"
                />
                <Carousel.Caption style={{ color: 'white' }}>
                    <h3>Third slide</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item style={{ backgroundColor: '#19b997', height: '200px' }}>
                <img
                    className="d-inline-block float-start"
                    src={bulb}
                    alt="Forth slide"
                />
                <Carousel.Caption>
                    <h3>Forth slide</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default Rendercarousel;