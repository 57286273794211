import React from "react";
import { NavMenu } from "../NavMenu/NavMenu";
import { Footer } from "../Footer/Footer";


export const Layout = (props) => {
    return (
        <div>
            <NavMenu />
            {props.children}
            <Footer />
        </div>
    );
};
