import Geocode from "react-geocode";
import { useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Loader } from "../Loader/Loader";

function GoogleMaps(props) {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loaded, setLoaded] = useState(false);

    Geocode.setApiKey(process.env.REACT_APP_GI_MAP_API_KEY);

    Geocode.setLanguage("en");

    Geocode.setLocationType("ROOFTOP");

    Geocode.fromAddress(`${props.tradingAddress}`).then(
        (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            setLatitude(lat);
            setLongitude(lng);
            setLoaded(true);
        },
        (error) => {
            console.error(error);
        }
    );

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GI_MAP_API_KEY,
    });

    const mapContainerStyle = {
        height: "100%",
        width: "100%",
    };

    if (loadError) return "Error Map Failed to Load";
    if (!isLoaded) return <Loader page="map" />;

    return (
        <div style={{ height: `100%` }}>
            {loaded ? (
                <GoogleMap
                    id="map"
                    mapContainerStyle={mapContainerStyle}
                    zoom={15}
                    center={{ lat: Number(latitude), lng: Number(longitude) }}
                >
                    <Marker
                        position={{
                            lat: Number(latitude),
                            lng: Number(longitude),
                        }}
                    />
                </GoogleMap>
            ) : (
                <Loader page="map" />
            )}
        </div>
    );
}

export default GoogleMaps;
