import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Accordion from "react-bootstrap/Accordion";

import styles from "../Profile.module.css";
import Button from "../../../components/Button/Button.jsx";

export const YourOrganisation = ({
    supplier,
    onClick,
    areasCovered,
    serviceTypes,
    activeAccordion,
    nextAccordionSection,
}) => {
    const [supplierServices, setSupplierServices] = useState([]);

    const {
        trading_Address_Line_1,
        trading_Address_Line_2,
        trading_Address_Line_3,
        trading_Postcode,
        trading_City,
        areas_Covered,
        services,
    } = supplier;

    const createSupplierServicesArray = useCallback(() => {
        let supplierServices = [];

        services?.forEach((service) => {
            supplierServices.push(service.service_Type);
        });

        setSupplierServices(supplierServices);
    }, [services]);

    useEffect(() => {
        createSupplierServicesArray();
    }, [createSupplierServicesArray]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            tradingAddressLine1: "",
            tradingAddressLine2: "",
            tradingAddressLine3: "",
            city: "",
            postcode: "",
        },
        mode: "onChange",
    });

    setValue("tradingAddressLine1", trading_Address_Line_1);
    setValue("tradingAddressLine2", trading_Address_Line_2);
    setValue("tradingAddressLine3", trading_Address_Line_3);
    setValue("postcode", trading_Postcode);
    setValue("city", trading_City);

    const myClickEvent = () => {
        onClick(nextAccordionSection);
    };
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1" className={styles.sectionContainer}>
                    <Accordion.Header style={{ fontFamily: "inherit", fontWeight: "normal" }}>
                        Your organisation
                    </Accordion.Header>
                    <Accordion.Body className={styles.formContainer}>
                        <form action="" onSubmit={handleSubmit((data) => data)}>
                            <div>
                                <label htmlFor="tradingAddressLine1">Trading address line 1:</label>
                                <input
                                    type="text"
                                    {...register("tradingAddressLine1")}
                                    id=""
                                    placeholder="Trading address line 1"
                                />
                                {errors.tradingAddressLine1 && (
                                    <p className={styles.error}>{errors.tradingAddressLine1.message}</p>
                                )}
                            </div>
                            <div>
                                <label htmlFor="tradingAddressLine2">Trading address line 2:</label>
                                <input
                                    type="text"
                                    {...register("tradingAddressLine2")}
                                    id=""
                                    placeholder="Trading address line 2"
                                />
                                {errors.tradingAddressLine2 && (
                                    <p className={styles.error}>{errors.tradingAddressLine2.message}</p>
                                )}
                            </div>
                            <div>
                                <label htmlFor="tradingAddressLine3">Trading address line 3:</label>
                                <input
                                    type="text"
                                    {...register("tradingAddressLine3")}
                                    id=""
                                    placeholder="Trading address line 3"
                                />
                                {errors.tradingAddressLine3 && (
                                    <p className={styles.error}>{errors.tradingAddressLine3.message}</p>
                                )}
                            </div>
                            <div>
                                <label htmlFor="city">Trading city:</label>
                                <input type="text" {...register("city")} id="" placeholder="Trading city" />
                                {errors.city && <p className={styles.error}>{errors.city.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="postcode">Trading postcode:</label>
                                <input type="text" {...register("postcode")} id="" placeholder="Trading postcode" />
                                {errors.postcode && <p className={styles.error}>{errors.postcode.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="areasCovered">Areas covered:</label>
                                <Accordion className={styles.areasDropdownContainer}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Areas covered:</Accordion.Header>
                                        <Accordion.Body className={styles.areasContainer}>
                                            {areasCovered &&
                                                areasCovered.map((area) => {
                                                    return (
                                                        <div key={area}>
                                                            <input
                                                                type="checkbox"
                                                                name={area}
                                                                id=""
                                                                value={area}
                                                                defaultChecked={
                                                                    areas_Covered?.includes(area) ? true : false
                                                                }
                                                            />
                                                            <label htmlFor={area}>{area}</label>
                                                        </div>
                                                    );
                                                })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {errors.firstName && <p className={styles.error}>{errors.firstName.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="businessOverview">Service Type:</label>
                                <Accordion className={styles.areasDropdownContainer}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Service type:</Accordion.Header>
                                        <Accordion.Body className={styles.areasContainer}>
                                            {serviceTypes &&
                                                serviceTypes.map((service) => {
                                                    return (
                                                        <div key={service}>
                                                            <input
                                                                type="checkbox"
                                                                name={service}
                                                                id=""
                                                                value={service}
                                                                defaultChecked={
                                                                    supplierServices.includes(service) ? true : false
                                                                }
                                                            />
                                                            <label htmlFor={service}>{service}</label>
                                                        </div>
                                                    );
                                                })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                {errors.lastName && <p className={styles.error}>{errors.lastName.message}</p>}
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    flex: "1",
                                }}
                            >
                                <Button
                                    colour="Purple"
                                    text="Next"
                                    disabled={!isValid}
                                    onClick={myClickEvent}
                                    height="50px"
                                    width="200px"
                                    arrow={true}
                                />
                            </div>
                        </form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};
