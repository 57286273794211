import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import styles from "./Home.module.css";
import { PageCard } from "../../components/PageCard/PageCard";

import buyers from "../../assets/images/pageIcons/ge-buyers.png";
import suppliers from "../../assets/images/pageIcons/ge-suppliers.png";
import leaf from "../../assets/images/pageIcons/ge-leaf.png";
import marketplace from "../../assets/images/pageIcons/ge-marketplace.png";
import plpartnerlogo from "../../assets/images/Business/PG logo.png";
import BNZPartnerLogo from "../../assets/images/Business/bee-net-zero.png";

import PSE from "../../assets/images/CaseStudies/PSE.png";
import ellard from "../../assets/images/CaseStudies/Ellard.png";
import KAST from "../../assets/images/CaseStudies/KAST.png";
import newInterBanner from "../../assets/images/banners/bg-garden.png";

import { setDescription, setTitle } from "../../utils";
import Social from "../../components/Social/social";
import Campaign from '../../components/CampaignBanner/Campaign';
import Btn from "../../components/Button/Button";
import Carousel from 'react-bootstrap/Carousel';
import CampaignCarousel from "../../components/Carousel/Carousel";

const Home = () => {
    const nextIcon = <span className={`gcicon-right-open ${styles.arrowIcon}`}></span>
    const prevIcon = <span className={`gcicon-left-open ${styles.arrowIcon}`}></span>

    useEffect(() => {
        setTitle("Green Economy - Green business growth, net zero technology");
        setDescription("Green Economy represents businesses that design, manufacture, supply, provide or install green technology products and services.")
    }, []);
    
    return (
        <>
            <Banner
                title="GREEN ECONOMY"
                src={bannerImg}
                alt="Home Banner"
                text="BUILDING A GREENER, NET ZERO ECONOMY"
                subText="POWERED BY LOCAL SUPPLIERS"
                color="white"
                bgcolour="#02be7c"
            />

            <div className={styles.homeContainer} style={{paddingBottom:'0'}}>
                <div className={styles.blurbContainer}>
                    <div className={styles.blurb}>
                        <h2>Green Economy provides end-to-end support to businesses as the UK transitions to net zero.</h2>
                        <p className="mb-3">
                            We help businesses to decarbonise with advice, training and support that delivers long term resilience, environmental credibility and financial savings.
                        </p>
                        <p className="mb-3">
                            Simultaneously we help to grow local green technologies and services businesses, increasing their visibility, improving their competitiveness and providing access to new sales opportunities.
                        </p>
                        <p>
                            By supporting the entire green supply chain we aim to deliver an equitable net zero transition, working with local government and combined authorities to implement business support that simplifies the business path to net zero, whilst removing barriers linked to slow or low adoption of green technologies.
                        </p>
                    </div> 
                    <div className={styles.blurbimg } >
                        <img alt="GE Garden" src={newInterBanner} width='90%'></img>
                    </div>
                </div>
            </div>
            <div className={styles.homeContainer}>
                <div className={styles.homeCardContainer}>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="Transition to net zero"
                            text="Need help to decarbonise your business, improve your environmental credentials and save money?"
                            subText="Access resources and support from our Sustainability and Net Zero team."
                            page="buyers"
                            hasLogo={true}
                            logo={buyers }
                            link={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero"}
                            externalLink={true}
                            styling="light"
                        />
                    </div>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="Green business growth"
                            text="Do you install or supply low carbon technologies or provide green solutions?"
                            subText="Sell your products/services  and raise your profile through our online marketplace"
                            page="suppliers"
                            hasLogo={true}
                            logo={suppliers}
                            link={process.env.REACT_APP_GI_URI + "services/green-business-growth"}
                            externalLink={true}
                            styling="light"
                        />
                    </div>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="Grow your green economy"
                            text="Does your local authority or city-region want to grow its green economy?"
                            subText="Green Economy helps businesses to transition to net zero whilst growing your local green technologies and services supply chain to reap the economic benefits."
                            page="public"
                            hasLogo={true}
                            logo={leaf}
                            link={process.env.REACT_APP_GI_URI + "services/grow-your-green-economy"}
                            externalLink={true}
                            styling="light"
                        />
                    </div>
                </div>

                <PageCard
                    width="100%"
                    header="Join or search the Marketplace"
                    text="Our directory of installers, equipment suppliers and solution providers allowing buyers to find and connect with local firms. "
                    page="marketplace"
                    hasLogo={true}
                    logo={marketplace}
                    link="marketplace"
                    styling="marketplace"
                    subText="List your business or find your perfect project partner. "
                />
            </div>
            <div className={styles.homeContainer}>
                <div className={styles.homeCardContainer}>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="NEWS"
                            text="Environmental business news"
                            page="news"
                            hasLogo={false}
                            link={process.env.REACT_APP_GI_URI + "/news/"}
                            styling="link"
                            btnText="Latest news"
                            externalLink={true}
                        />
                    </div>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="CASE STUDIES"
                            text="We've supported businesses just like yours"
                            page="casestudies"
                            hasLogo={false}
                            link={process.env.REACT_APP_GI_URI + "/case-studies/"}
                            styling="link"
                            btnText="Case Studies"
                            externalLink={true}
                        />
                    </div>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="BLOGS"
                            text="Read industry insights and more"
                            page="public"
                            hasLogo={false}
                            link={process.env.REACT_APP_GI_URI + "/blogs/"}
                            styling="link"
                            btnText="Read blogs"
                            externalLink={true}
                        />
                    </div>
                    <div className={styles.homeCard}>
                        <PageCard
                            width="100%"
                            header="Reports"
                            text="Gain access to intelligence reports and learn more about your market."
                            page="public"
                            hasLogo={false}
                            link={process.env.REACT_APP_GI_URI + "/reports/"}
                            styling="link"
                            btnText="View reports"
                            externalLink={true }
                        />
                    </div>
                </div>
            </div>
            
            <div className={styles.magContainer}>
                <h3>What our members and clients say about Green Economy:</h3>
                    <div className={styles.testcontainer}>
                        <div className={styles.homeCard}>
                            <PageCard
                                width="100%"
                                header="Gary Brandwood, Managing Director, Perfect Sense Energy"
                                text="Being a member of the Green Economy is a great way of identifying sales opportunities to grow your business. The Marketplace allows us to gain access to a host of business growth benefits, including a personal profile for buyers to directly enquire about our services."
                                subText="We look forward to continuing our membership and work with the Green Economy to help future clients transition to net zero."
                                page=""
                                hasLogo={true}
                                logo={PSE}
                                styling="casestudy"
                            />
                        </div>
                         <div className={styles.homeCard}>
                            <PageCard
                                width="100%"
                                header="John McGorrigan, Ellard"
                                text="We were looking at a solar roof project to further reduce our carbon emissions in conjunction with our landlords.However, we had no experience of such a project, and the internet just introduced us to a minefield."
                                subText="I can't recommend the service enough. It's saved us loads of time, effort and research, by being put in touch directly with expert suppliers."
                                page=""
                                hasLogo={true}
                                logo={ellard}
                                styling="casestudy"
                            />
                        </div>
                        <div className={styles.homeCard}>
                            <PageCard
                                width="100%"
                                header="Stephen Dootson, Commercial Director, KAST Renewable Energies"
                                text="KAST Renewable Energies are delighted to be members of Green Economy, the focus on business growth in sustainability and renewable technologies is welcomed and appreciated."
                                subText="Their Marketplace has generated multiple enquiries for our services and the promotion of Green Economy opportunities to members has also helped us to win new business."
                                page=""
                                hasLogo={true}
                                logo={KAST}
                                styling="casestudy"
                            />
                        </div>            
                </div>
            </div>
            <div className={styles.partnerContainer }>
                <h3>Our partners:</h3>
                <div style={{ textAlign:'center'} }>
                    <img src={plpartnerlogo} alt="pg" />
                    <img src={BNZPartnerLogo } alt="bnz" />
                </div>
            </div>
            <div className="mb-4">
                <Social/>
            </div>

            {//<div>
            //    <Campaign title="Insulation Campaign" template={4} />
                //</div>
            }
        </>
    );
};

export default Home;
