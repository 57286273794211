import { useState } from "react";
import Button from "../Button/Button.jsx";
import styles from "./SupplierUSPSection.module.css";
import GoogleMaps from "../Map/Map.jsx";
import MailModal from "../MailModal/EnquiryModal"

export const SupplierDetailsSection = ({ supplier }) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const tradingAddress = [
        supplier.trading_Address_Line_1,
        supplier.trading_Address_Line_2,
        supplier.trading_Address_Line_3,
        supplier.trading_City,
        supplier.trading_Postcode,
    ]
        .filter(Boolean)
        .join(", ");

    return (
        <div className={styles.container}>
            <MailModal show={show} handleClose={handleClose} title={supplier.account_Name} email={supplier.email} template={1} />
            <div className={styles.mapContainer}>
                <GoogleMaps tradingAddress={tradingAddress} />
            </div>
            <div className={styles.locationsContainer}>
            <div className={styles.textContainer}>
                <p className={styles.header}>Trading Address</p>
                <p>{supplier.trading_Address_Line_1 && supplier.trading_Address_Line_1}</p>
                <p>{supplier.trading_Address_Line_2 && supplier.trading_Address_Line_2}</p>
                <p>{supplier.trading_Address_Line_3 && supplier.trading_Address_Line_3}</p>
                <p>{supplier.trading_City && supplier.trading_City}</p>
                <p>{supplier.trading_Postcode && supplier.trading_Postcode}</p>
            </div>
            {supplier.areas_Covered.length!==0 &&
            <div className={styles.textContainer}>
                <p className={styles.header}>Areas Covered</p>
               
                {supplier.areas_Covered.map((area, index) => (
                    <p key={index}><span className={`gcicon-map-marker ${styles.pinIcon}`}></span>{area}</p>

                ))}
            </div>}
            </div>
            <div className={styles.btnContainer}>
                <Button color="Green" onClick={handleShow} text="Make enquiry / Request quote" width="75%" />
            </div>
        </div>
    );
};



