export const handleTabStateBasedOnURL = () => {
    let URL = window.location.href;

    URL = URL.split("/");

    let currentTab = URL[URL.length - 1];

    if (currentTab === "account") {
        currentTab = "your account";
    }

    if (currentTab === "dashboard") {
        currentTab = "overview";
    }

    return currentTab;
};

export const setActiveTab = (currentTab) => {
    const tabs = document.querySelectorAll(".navTab");

    for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        tab.classList.remove("current");

        if (tab.innerText?.toLowerCase() === currentTab) {
            tab.classList.add("current");
        }
    }
};

export const handleTabStateManagement = (tabName, parent, tabState, setTabStates) => {
    switch (tabName) {
        case "overview":
            if (!parent.classList.contains("active")) {
                parent.classList.add("active");
                setTabStates((prevState) => ({
                    ...prevState,
                    isOverviewActive: true,
                    isProfileActive: false,
                    isYourAccActive: false,
                }));
            }
            break;
        case "profile":
            if (!parent.classList.contains("active")) {
                parent.classList.add("active");
                setTabStates((prevState) => ({
                    ...prevState,
                    isOverviewActive: false,
                    isProfileActive: true,
                    isYourAccActive: false,
                }));
            }
            break;
        case "account":
            if (!parent.classList.contains("active")) {
                parent.classList.add("active");
                setTabStates((prevState) => ({
                    ...prevState,
                    isOverviewActive: false,
                    isOrdersActive: false,
                    isProfileActive: false,
                    isYourAccActive: true,
                }));
            }
            break;
        default:
            break;
    }
};

export const getAccountInfo = async (account = "be.ev") => {
    try {
        const response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "supplier", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify({ accountname: account }),
        });

        const { data } = await response.json();
        if (data.length < 1) {
            return data;
        }
        return data[0];
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something's went wrong" });
    }
};

export const getAccountInfoById = async (accountId) => {
    try {
        const response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "account", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify({ accountId: accountId }),
        });

        const { data } = await response.json();
        if (data.length < 1) {
            return data;
        }
        return data[0];
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something's went wrong" });
    }
};

export const getCompanyDetailsFormFields = async () => {
    const formFields = [
        "Account_Type",
        "VAT_Number",
        "Type_of_Business",
        "Trade_Association_Competency_Schemes",
        "Trading_Address_Line_2",
        "Construction_Contractor_Schemes",
        "Areas_Covered",
        "Service_Type",
        "Product_Measure_Standards",
    ];

    try {
        let response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "lookup", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(formFields),
        });

        response = await response.json();
        if (response.code === "SUCCESS") {
            return response;
        } else {
            throw new Error({ message: "Uh oh, looks like something went wrong" });
        }
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

let cachedSearchData = [];
let cachedQueryInput = undefined;

export const fetchSearchData = async (searchObject) => {
    const query = JSON.stringify(searchObject);

    if (query === cachedQueryInput) {
        return cachedSearchData;
    }
    try {
        const response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "search", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(searchObject),
        });

        const { data } = await response.json();

        if (!data) {
            throw new Error();
        }

        cachedQueryInput = query;
        cachedSearchData = data;

        return data;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const postEnquiryEmailData = async (emailObject) => {
    let sent = true;
    try {
        await fetch(process.env.REACT_APP_GI_API_BASEURL + "enquiries", {
            method: "POST",
            headers: [["Content-Type", "application/json"]],
            body: JSON.stringify(emailObject),
        }).then((response) => {
            if (!response.ok) throw new Error(response.status);
            else return true
        }).then((data) => {
            sent = data;
        }).catch((error) => {
            sent = false;
        });    

        return sent;
    } catch (error) {
        throw new Error({ message: "Uh oh, looks like something went wrong" });
    }
};

export const addSearchResultsToLocalStorage = (searchArray) => {
    localStorage.setItem("search-results", JSON.stringify(searchArray));
};

export const getSearchResultsFromLocalStorage = () => {
    const searchResults = localStorage.getItem("search-results");

    if (searchResults !== undefined || searchResults !== null) {
        const parsedSearchResults = JSON.parse(searchResults);
        return parsedSearchResults;
    }
};

export const addEventsToLocalStorage = (eventResults, sortByName) => {
    if (eventResults) {
        if (getEventsFromLocalStorage()!== JSON.stringify(eventResults))
            localStorage.setItem("event-results", JSON.stringify(eventResults));
    }
};

export const getEventsFromLocalStorage = (component) => {
    const events = localStorage.getItem("event-results");
    const parsedSortBy = JSON.parse(events);

    if (events) {
        return parsedSortBy;
    }
};

export const addSortByToLocalStorage = (sortedResults, sortByName) => {
    localStorage.setItem("sort-by", JSON.stringify(sortByName));
};

export const getSortByFromLocalStorage = (component) => {
    const sortBy = localStorage.getItem("sort-by");
    const parsedSortBy = JSON.parse(sortBy);

    if (sortBy) {
        return parsedSortBy;
    }
};

export const saveInputsToLocalStorage = (inputs) => {
    localStorage.setItem("search-inputs", JSON.stringify(inputs));
};

export const getInputsFromLocalStorage = () => {
    const inputs = localStorage.getItem("search-inputs");
    const parsedInputs = JSON.parse(inputs);

    if (inputs) {
        return parsedInputs;
    }
};

export const saveShowResultsToLocalStorage = (showResults) => {
    localStorage.setItem("show-results", showResults);
};

export const getShowResultsFromLocalStorage = () => {
    const showResults = localStorage.getItem("show-results");
    const parsedShowResults = JSON.parse(showResults);

    if (showResults) {
        return parsedShowResults;
    }
};

export const savePageNumberToLocalStorage = (pageNumber) => {
    localStorage.setItem("page-number", pageNumber);
};

export const getActivePageNumberFromLocalStorage = () => {
    const pageNumber = localStorage.getItem("page-number");
    const parsedPageNumber = JSON.parse(pageNumber);

    if (pageNumber) {
        return parsedPageNumber;
    }
};

export const clearLocalStorage = () => {
    localStorage.clear();
};

export const truncateString = (string) => {
    if (string) {
        return string.length > 200 ? string.substring(0, 200) + "..." : string;
    }
};

export const sortMarketplaceSuppliers = (suppliers, sortByName, searchResults) => {
    switch (sortByName) {
        case "ascending":
            suppliers.sort((a, b) => {
                if (a.account_Name < b.account_Name) {
                    return -1;
                }
                if (a.account_Name > b.account_Name) {
                    return 1;
                }
                return 0;
            });
            break;
        case "descending":
            suppliers.sort((a, b) => {
                return b.account_Name.localeCompare(a.account_Name);
            });
            break;
        case "high":
            suppliers.sort((a, b) => {
                return b.stars - a.stars;
            });
            break;
        case "low":
            suppliers.sort((a, b) => {
                return a.stars - b.stars;
            });
            break;
        case "distance":
            suppliers.sort((a, b) => {
                if (a.distance < b.distance) {
                    return -1;
                }
                if (a.distance > b.distance) {
                    return 1;
                }
                return 0;
            });
            break;
        case "all":
            suppliers = searchResults;
            break;
        default:
            return suppliers;
    }
    return suppliers;
};

export const setTitle = title => {
    const el = document.querySelector('title');
    /*el.innerText = `${el.text} | ${title}`;*/
    el.innerText = `${title}`;
};

export const setDescription = desc => {
    const el = document.querySelector("meta[name='description']");
    el.setAttribute('content', desc)
}

export const getPlans = async () => {
    try {
        let response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "plans", {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });

        response = await response.json();
        if (response.code === "SUCCESS") {
            return response.data;
        } else {
            throw new Error({ message: "Uh oh, looks like something went wrong" });
        }
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something's went wrong" });
    }
};

export const getEvents = async () => {
    try {
        let response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "events", {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });

        response = await response.json();
        if (response.code === "SUCCESS") {
            return response.data;
        } else {
            throw new Error({ message: "Uh oh, looks like something went wrong" });
        }
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something's went wrong" });
    }
};

export const getOpportunities = async () => {
    try {
        let response = await fetch(process.env.REACT_APP_GI_API_BASEURL + "opportunities", {
            method: "GET",
            headers: [["Content-Type", "application/json"]]
        });

        response = await response.json();
        if (response.code === "SUCCESS") {
            return response.data;
        } else {
            throw new Error({ message: "Uh oh, looks like something went wrong" });
        }
    } catch (e) {
        throw new Error({ message: "Uh oh, looks like something's went wrong" });
    }
};
