import React, { useState, useEffect } from "react";

import MailModal from "../../components/MailModal/EnquiryModal";
import { PageCard } from "../../components/PageCard/PageCard";

import { setDescription, setTitle } from "../../utils";
import Banner from "../../components/Banner/Banner";
import bannerImg from "../../assets/images/banners/GE-Banner.png";

import cfac from '../../assets/images/misc/CfaC.jpg';
import cfal from '../../assets/images/pageIcons/cfal.png';

import styles from "./accreditations.module.css";

const Accreditations = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setTitle("Green Economy | Accreditations: provide business assurances");
        setDescription("Demonstrate your expertise to new suppliers with quality assurances, accreditations and standards")
    }, []);

    return (
        <>
            <Banner
                title="ACCREDITATIONS"
                src={bannerImg}
                alt="Research"
                text="DEMONSTRATE YOUR EXCELLENCE"
                subText="TO SECURE NEW CUSTOMERS"
                color="white"
                bgcolour="#02be7c"
            />
            <div className={styles.accrContainer}>
                <h2 className="mb-2">Provide assurance to your customers with accreditations that demonstrate your competence, skills and expertise.</h2>
                <p className="FirstPara mb-2">When encouraging a customer to invest in your products and services, it is critical to demonstrate that your business and your people have the necessary skills and experience to deliver.
                    This becomes increasingly important when bidding for government contracts or moving into Tier 1 supply chains, where certain accreditations are a baseline to be considered.
                    Below we've outlined some of the key accreditations and assurances, linking to some of our partners who can. </p>
                <MailModal show={show} handleClose={handleClose} title="Enquiry" email="info@greeneconomy.co.uk" template={2} />
                <p className="mb-2">Green Economy can help you to understand the right pathway for your business - <a onClick={handleShow} className="PLinkTo">get in touch to access support.</a> </p>
                <PageCard
                    width="100%"
                    header="ISO Management Systems - Quality Management, Information Security and Business Continuity"
                    text="ISO Management Systems are the internationally recognised certification process which demonstrate excellence across your operation. Certifications are offered across a variety of specialisms including Quality Management (ISO 9001), 
                        Information Security (ISO 27001) and Business Continuity Management (ISO 22301). "
                    page="ISO"
                    hasLogo={false}
                    styling="link"
                    link="https://www.centreforassessment.co.uk/all-services/"
                    externalLink={true}
                    btnText="Certification Services"
                />
                <h3 className="mb-2 mt-4">Demonstrate your environmental commitment</h3>
                <div className={styles.cfaContainer}>
                    <div>
                        <img src={cfac } alt="cfa"/>
                    </div>
                    <div className={styles.cfaText}>
                        <p className="mb-2">Our partner, Centre for Assessment, provides a range of green certification services, helping organisations to put the environment first, and validate these efforts and commitments.</p>
                        <p className="mb-2">They have a diverse collection of environmental-focused certification and verification services and have worked hard to offer standards that champion organisations' journeys to net zero, but also to push businesses to do more - to challenge what it means to be green today:</p>
                        <uL>
                            <li>To reduce operational costs</li>
                            <li>To improve your environmental management</li>
                            <li>To stay competitive</li>
                            <li>To comply with legal requirements</li>
                        </uL>
                    </div>
                </div>
                <div className={styles.accrCardContainer}>
                    <PageCard
                        width="100%"
                        header="PAS 2060 (Carbon Neutrality) certification"
                        text="Certify your organisation to PAS 2060 - the specification that demonstrates your commitment to carbon neutrality:"
                        subText="Commercial activities can contribute to greenhouse gas emissions which exacerbate the human impact on global warming. Companies may be keen to become 'carbon neutral' by changing their practices,
                            but how can they be sure they are doing all they can to minimise the effects of climate change?<br/><br>
                            PAS 2060 provides a framework to help you measure, reduce and offset your impact on our climate. It will help you prove that you are truly committed to the challenge of environmental responsibility.<br/><br/>
                            This certification will show that your organisation knows what your carbon footprint is and that you take the issue of carbon footprint seriously. 
                            It will also demonstrate your plans to reduce that footprint and be open and transparent in your approach. The PAS 2060 specification will also help you identify what emissions you can't eliminate and challenge you 
                            to offset them by purchasing carbon credits. "
                        hasLogo={false}
                        styling="standardNoImageLine"
                    />
                    <PageCard
                        width="100%"
                        header="ISO 14001 - Environmental Management System"
                        text="Reduce company waste and protect our environment with UKAS-accredited certification."
                        subText="Every responsible business leader has concerns about how their company's activities are impacting on the environment. Water use, energy use, pollution and waste are some factors that may negatively affect your local, 
                            national and international environment. These factors may also reduce your profitability and reputation if not managed effectively.<br/><br>
                            ISO 14001 is a framework to help your organisation address these issues. It prescribes controls that should be in place for you to have an Environmental Management System (EMS) that is right for you and your context."
                        hasLogo={false}
                        styling="standardNoImageLine"
                    />
                </div>
                <PageCard
                    width="100%"
                    header="Win more business, produce better products or services - and stand out from the competition "
                    text="Alongside Green Economy, Centre for Assessment is part of The Growth Company and is a leading certification provider."
                    subText="Centre for Assessment has over 20 years of experience in working with certification clients. They are accredited by the United Kingdom Accreditation Service (UKAS) and are an ethical and approachable certification body 
                        with a passion for meeting the needs of their clients - providing certification to ISO and sector-specific standards, as well as specialist training services.<br/><br/>
                        With their experienced team of auditors located across the UK and beyond, Centre for Assessment can support your organisation's journey towards achieving certification to a recognised standard. 
                        They work in partnership with our clients, helping them achieve their goals. "
                    page="CfA"
                    hasLogo={true}
                    logo={cfal }
                    styling="standardDark"
                    link="https://www.centreforassessment.co.uk/about-us/"
                    externalLink={true}
                    btnText="Centre for Assessment"
                />
            </div>
        </>

     )
}


export default Accreditations;