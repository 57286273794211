import React from "react";
import styles from "./OverlayPageCard.module.css";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import "./OverlayPageCard.css";

const popover = (id, title, text) => {
    return (
        <Popover id={`popover-${id}`}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
                {text.map((t) =>
                    <p style={{ marginBottom: `5px` }} key={t}>
                        {t.replace(/'\\/g, "\\'")}
                    </p>
                )}
            </Popover.Body>
        </Popover>
    )
}

export const  OverlayPageCard = (props) => {

    return (   
        <OverlayTrigger key={props.id} placement="auto" overlay={popover(props.id, props.name + ' - ' + props.position, props.text)}>
            <div className={`${styles.Card} ${props.styling + "Card"}`}>
                <div className={styles.Image}>
                    <img src={props.img} alt={props.name} />
                </div>
                <div className={styles.CardDetail}>
                    <h3 className={styles.CardTitle}>{props.name}</h3>
                    <p>{props.position}</p>
                    {props.linkedin && props.linkedin !== '' &&
                        <p>
                            <a rel='noreferrer'
                                target='_blank'
                                style={{ color: 'var(--green-colour)', textDecoration: 'underline' }}
                                href={props.linkedin} >
                                LinkedIn
                            </a>
                        </p>
                    }
                    {props.report && props.report !== '' &&
                        <p>
                            <a rel='noreferrer'
                                target='_blank'
                                style={{ color: 'var(--green-colour)', textDecoration: 'underline' }}
                                href={props.report} >
                                Report
                            </a>
                        </p>
                    }
                    {props.companysite && props.companysite !== '' &&
                        <p>
                            <a rel='noreferrer'
                                target='_blank'
                                style={{ color: 'var(--green-colour)', textDecoration: 'underline' }}
                                href={props.companysite} >
                                Company
                            </a>
                        </p>
                    }
                </div>
            </div>
        </OverlayTrigger>
    )

}

