import { useParams } from "react-router-dom";
import { useEffect } from "react";

const RegisterUser = ({ auth }) => {
    const { accountId } = useParams();
    const customState = { accountId: accountId };

    useEffect(() => {
        const reegister = async () => {
            await auth.signinRedirect({ extraQueryParams: customState });
        };
        reegister();
    }, []);

    return (
        null
    );
};

export default RegisterUser;
