import Col from "react-bootstrap/Col";
import { CompanyLine } from "./CompanyLine";

import style from "./marketplace.module.css";

import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";

export const Results = ({ error, isLoading, displayItems, activePage }) => {
    if (isLoading) {
        return <Loader page="marketplace" />;
    }

    if (error) {
        return <Error error={error} id="dashboard-error" page="marketplace-page" />;
    }

    if (displayItems?.length === 0) {
        return <h4>No results found</h4>;
    }

    return (
        <div className="results-container">
            <Col>
                <div className={`${style.dListing}`}>
                    <ol>
                        {displayItems && displayItems.length ? (
                            displayItems.map((item) => (
                                <li className={style.cardContainer} key={item.id}>
                                    <CompanyLine
                                        title={item.account_Name}
                                        rating={item.stars}
                                        verified={item.verified}
                                        description={item.business_Overview}
                                        img={item.companyLogo}
                                        imgType={item.companyLogoType}
                                        accr={item.standards_Accreditations}
                                        activePage={activePage}
                                        distance={item.distance}
                                    />
                                </li>
                            ))
                        ) : (
                            <h4>No results found</h4>
                        )}
                    </ol>
                </div>
            </Col>
        </div>
    );
};
