import { useState, useEffect } from 'react'

export const useScript = (url, name, id, cbid = null) => {

    const [lib, setLib] = useState({})

    useEffect(() => {
        const script = document.createElement('script')

        script.src = url
        script.async = true
        script.id = id
        script.dataset.cbid = cbid
        script.onload = () => setLib({ [name]: window[name] })

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url, name, id])

    return lib

}