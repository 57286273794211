import { useState } from "react";
import Form from "react-bootstrap/Form";

import { addSortByToLocalStorage, sortMarketplaceSuppliers } from "../../utils";

const sortByOptions = [
    {
        name: "Alphabetically (A-Z)",
        short: "ascending",
    },
    {
        name: "Alphabetically (Z-A)",
        short: "descending",
    },
    {
        name: "Distance",
        short: "distance",
    },
    {
        name: "Show all",
        short: "all",
    },
];

export const SortBy = ({ displayItems, setDisplayItems, searchResults, location }) => {
    const [sortOptions, setSortOptions] = useState({
        ascending: false,
        descending: false,
        high: false,
        low: false,
        distance: false,
        all: false,
    });

    const handleCheck = (e) => {
        const sortByName = e.target.value;

        let sortedSearchResults = [...displayItems];

        const sortedSuppliers = sortMarketplaceSuppliers(sortedSearchResults, sortByName, searchResults);

        addSortByToLocalStorage(sortedSuppliers, sortByName);
        setDisplayItems(sortedSuppliers);
    };

    const isDisabled = (option) => {
        if (option.short == "distance" && displayItems[0].distance == null) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Form.Select
                data-testid="sort-by"
                aria-label="Sort by:"
                className="sortByContainer"
                onChange={(event) => handleCheck(event, "location")}
            >
                <option value="Sort by:">Sort by:</option>
                {sortByOptions.map((option) => {
                    return (
                        <option
                            value={option.short}
                            key={option.name}
                            type="checkbox"
                            label={option.name}
                            id={option.name}
                            data-testid={option.short}
                            name={option.short}
                            disabled={isDisabled(option)}
                        >
                            {option.name}
                        </option>
                    );
                })}
            </Form.Select>
        </>
    );
};
