import { Card } from "react-bootstrap";
import { SupplierHeader } from "../../components/SupplierHeader/SupplierHeader";
import styles from "./YourAccount.module.css";

export const YourAccount = ({ supplier }) => {
    return (
        <div className={styles.mainContent}>
            <SupplierHeader supplier={supplier} />
            <div className={styles.innerContent}>
                <Card className={styles.accountCard}>
                    <Card.Body>
                        <Card.Title>Your details</Card.Title>
                        <Card.Text>Edit name, email and mobile number</Card.Text>
                    </Card.Body>
                </Card>
                <Card className={styles.accountCard}>
                    <Card.Body>
                        <Card.Title>Login & Security</Card.Title>
                        <Card.Text>Change password</Card.Text>
                    </Card.Body>
                </Card>
                <Card className={styles.accountCard}>
                    <Card.Body>
                        <Card.Title>Help</Card.Title>
                        <Card.Text>Contact GI for assistance</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};
