import styles from "./SupplierInfoSection.module.css";

export const SupplierInfoSection = ({ supplier }) => {

    const serviceProducts = supplier.services.reduce((map, { service_Line, service_Type, business_Activities }) => {       
        (map[service_Line] = map[service_Line] || []).push(service_Type + ' - ' + business_Activities.map((act) => {
            return (' ' + act)
        }));
        return map;
    }, {});

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <div className={styles.text}>
                    <div className={styles.sectionContainer}>
                        <div className={styles.section}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                
                                {supplier.companyLogo && (
                                    <div className={styles.imgContainer}>
                                        <img
                                            src={`data:image/png; charset=ISO-8859-1;base64,${supplier.companyLogo}`}
                                            alt={supplier.account_Name}
                                        />
                                    </div>
                                )}
                                <p className={styles.header}>Overview</p>
                            </div>
                            <p className={styles.overviewText}>
                                {supplier.business_Overview ? supplier.business_Overview : `No overview available`}
                            </p>
                        </div>
                        {supplier.services.length > 0 && supplier.services[0] !== "None" && (
                           
                            <div className={styles.section}>
                                
                                <hr />
                                <p className={styles.title}>Services / products</p>
                                <ul>
                                    {Object.keys(serviceProducts).map((service_Line) => {
                                        return (
                                            <li key={service_Line}>
                                                
                                                {service_Line !== "None" ? (
                                                    <span className={`gcicon-leaf ${styles.tickIcon}`}></span>
                                                ) : null}
                                                {service_Line === "None" ? "N/A" : service_Line}
                                                <ol>
                                                    {serviceProducts[service_Line].map((service, x) => {
                                                        return (
                                                            <li key={x}>
                                                                <div className={styles.wrapRow}>
                                                                    <div className={styles.wrapColumnTick}>
                                                                        {service !== "None" ? (
                                                                            <span className={`gcicon-delivery ${styles.tickIcon}`}></span>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className={styles.wrapColumnText}>
                                                                        {service === "None" ? "N/A" : service}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ol>
                                            </li>
                                        )} 
                                    )}
                                </ul>   
                            </div>

                        )}
                        {supplier.standards_Accreditations.length > 0 &&
                            supplier.standards_Accreditations[0] !== "None" && (
                                <div className={styles.section}>
                                    <p className={styles.title}>Accreditations</p>
                                    <ul>
                                        {supplier.standards_Accreditations.map((accreditation, i) => {
                                            return (
                                                <li key={i}>
                                                    {accreditation !== "None" ? (
                                                        <span className={`gcicon-award ${styles.tickIcon}`}></span>
                                                    ) : null}
                                                    {accreditation === "None" ? "N/A" : accreditation}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}
                        {supplier.certificates.length > 0 && supplier.certificates[0] !== "None" && (
                            <div className={styles.section}>
                                <p className={styles.title}>CSR Overview</p>
                                <ul>
                                    {supplier.certificates.map((certificate, i) => {
                                        return (
                                            <li key={i}>
                                                {certificate !== "None" ? (
                                                    <span className={`gcicon-customer ${styles.tickIcon}`}></span>
                                                ) : null}
                                                {certificate === "None" ? "N/A" : certificate}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {supplier.env_and_Social_Value.length > 0 && supplier.env_and_Social_Value[0] !== "None" && (
                            <div className={styles.section}>
                                <p className={styles.title}>Net Zero Reporting</p>
                                <ul>
                                    {supplier.env_and_Social_Value.map((value, i) => {
                                        return (
                                            <li key={i}>
                                                {value !== "None" ? (
                                                    <span className={`gcicon-grow ${styles.tickIcon}`}></span>
                                                ) : null}
                                                {value === "None" ? "N/A" : value}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
