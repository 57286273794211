import React, { useEffect } from "react";
import styles from "./Events.module.css"

import business from "../../assets/images/events/Business.jpg";
import others from "../../assets/images/events/Others.jpeg";
import workshop from "../../assets/images/events/Workshop.jpg";
import webinar from "../../assets/images/events/Webinar.jpg";
import internet from "../../assets/images/events/Internet.jpg";
import environment from "../../assets/images/events/Environment.jpg";
import sales from "../../assets/images/events/Sales.jpg";
import marketing from "../../assets/images/events/Marketing.jpg";
import leadership from "../../assets/images/events/Leadership.jpg";
import economy from "../../assets/images/events/Economy.jpg";


const EventImages = (props) => {
    const [srcImage, setSrcImage] = React.useState(null);
    useEffect(() => {
        switch (props.category) {
            case "BUSINESS":
                setSrcImage(business);
                break;
            case "OTHERS":
                setSrcImage(others);
                break;
            case "WEBINAR":
                setSrcImage(webinar);
                break;
            case "ENVIRONMENT":
                setSrcImage(environment);
                break;
            case "WORKSHOP":
                setSrcImage(workshop);
                break;
            case "INTERNET":
                setSrcImage(internet);
                break;
            case "SALES":
                setSrcImage(sales);
                break;
            case "MARKETING":
                setSrcImage(marketing);
                break;
            case "ECONOMY_AND_FINANCE":
                setSrcImage(economy);
                break;
            case "LEADERSHIP_AND_MANAGEMENT":
                setSrcImage(leadership);
                break;
            default:
                setSrcImage(others);
                break;
        }
    }, [props.category]);

    return (
        <img src={srcImage} alt={props.category} className={styles.responsive }/>
    );
};

export default EventImages;