import React, { useState, useEffect } from "react";
import styles from "./FilterSelect.module.css";

export const FilterSelect = ({ type, filterValue, data, initialValue, name, resetFilter }) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(initialValue);

    useEffect(() => {
        if (data.events) {
            let cats = [];
            switch (type) { 
                case "location":
                    cats = data.events.reduce((allData, cur) => {
                        return allData.includes(cur.location) ? allData : allData.concat([cur.location])
                    }, []);
                    break;
                case "year":
                    cats = data.events.reduce((allData, cur) => {
                        return allData.includes(cur.eventMonthYear) ? allData : allData.concat([cur.eventMonthYear])
                    }, []);
                    break;
                default: break;
            }
            setOptions(cats)
        }
    }, [data.events]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (resetFilter === true) {
            setSelected(initialValue);
            filterValue(initialValue);
        }
    }, [resetFilter, initialValue]);

    const handleSelect = (ev) => {
        ev.preventDefault();
        const selected = ev.target.value
        filterValue(selected);
        setSelected(selected)
    }
    return (
        <select className={`${styles.select}`}
            name={name}
            onChange={ev => handleSelect(ev)}
            value={selected }
        >
            <option value={initialValue}>{initialValue}</option>
            {
                options.map((option) => (
                    <option key={option} value={option}>{option}</option>
                ))
            }
        </select>
    );
};

export default FilterSelect;
