import { useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import "./Paginate.css"

const Paginate = (props) => {
    const pageListItems = [];
    const pageStartIndex = props.activePage * props.pageSize;

    const { activePage, setActivePage, items, pageSize, setDisplay, pageCount, setPageCount, isLoading } = props;

    useEffect(() => {
        setDisplay(items.slice(pageStartIndex, pageStartIndex + pageSize));
        setPageCount(Math.ceil(items.length / pageSize));
    }, [items, activePage, setDisplay, pageStartIndex, pageSize, setPageCount]);



    const pageRangeStart = Math.max(0, activePage - 2);
    const pageRangeEnd = Math.min(pageRangeStart + 4, pageCount - 1);
    const showInitialPage = activePage !== 0;
    const showPreviousPage = activePage > 0;
    const showFinalPage = activePage < pageCount - 1;
    const showNextPage = activePage < pageCount - 1;

    if (showInitialPage) {
        pageListItems.push(
            <Pagination.First
                key={"first"}
                onClick={() => {
                    setActivePage(0);
                    window.scrollTo(0, 600);
                }}
            />
        );
    }

    if (showPreviousPage ) {
        pageListItems.push(
            <Pagination.Prev
                key={"previous"}
                onClick={() => {
                    setActivePage(props.activePage - 1);
                    window.scrollTo(0, 600);
                }}
            />
        );
    }

    for (let number = pageRangeStart; number <= pageRangeEnd; number++) {
        pageListItems.push(
            <Pagination.Item
                key={number}
                active={number === props.activePage}
                onClick={() => {
                    setActivePage(number);
                    window.scrollTo(0, 600);
                }}
            >
                {number + 1}
            </Pagination.Item>
        );
    }

    if (showNextPage) {
        pageListItems.push(
            <Pagination.Next
                key={"nextt"}
                onClick={() => {
                    setActivePage(props.activePage + 1);
                    window.scrollTo(0, 600);
                }}
            />
        );
    }

    if (showFinalPage) {
        pageListItems.push(
            <Pagination.Last
                key={"last"}
                onClick={() => {
                    setActivePage(pageCount - 1);
                    window.scrollTo(0, 600);
                }}
            />
        );
    }
    return <>{!isLoading && pageCount !== 1 && <Pagination>{pageListItems}</Pagination>}</>;
};

export default Paginate;
